import { FC, createContext, useContext, useState, ReactNode } from 'react';

// @todo review sidebar context

export enum SideBarStates {
  ADDRESS_FORM,
  ADDRESS_MAP,
}

export interface SidebarContextProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  sidebarState: SideBarStates;
  setSidebarState: (sidebarState: SideBarStates) => void;
}

const SidebarContext = createContext<SidebarContextProps>({
  isOpen: false,
  // eslint-disable-next-line
  setIsOpen: () => {},
  sidebarState: SideBarStates.ADDRESS_FORM,
  // eslint-disable-next-line
  setSidebarState: () => {},
});

interface SidebarWrapperProps {
  children: ReactNode;
}

export const SidebarWrapper: FC<SidebarWrapperProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sidebarState, setSidebarState] = useState<SideBarStates>(SideBarStates.ADDRESS_FORM);

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        setIsOpen,
        sidebarState,
        setSidebarState,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export function useSidebarContext(): SidebarContextProps {
  return useContext(SidebarContext);
}
