import * as React from 'react';
function SvgChatNew(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M16.833 2.667a12.506 12.506 0 019.99 4.958 12.505 12.505 0 012.054 10.964 12.502 12.502 0 01-18.229 7.429l-6.791 3.235a.834.834 0 01-1.111-1.111L5.98 21.35A12.482 12.482 0 0116.831 2.666zm4.838 2.795a10.832 10.832 0 00-4.836-1.129h-.003a10.817 10.817 0 00-9.199 16.532c.15.241.167.542.045.799l-2.416 5.074 5.073-2.416a.835.835 0 01.799.045 10.817 10.817 0 009.624.907 10.83 10.83 0 004.737-16.641 10.842 10.842 0 00-3.824-3.168zm-10.532 8.665a1.253 1.253 0 011.578.155 1.246 1.246 0 01.155 1.579 1.248 1.248 0 01-1.923.189 1.245 1.245 0 01-.271-1.362 1.25 1.25 0 01.46-.561zm.854 1.425a.42.42 0 00.25-.466.415.415 0 00-.793-.077.414.414 0 00.543.543zm4.146-1.425a1.25 1.25 0 111.392 2.08 1.25 1.25 0 01-1.393-2.08zm.854 1.425a.42.42 0 00.249-.466.415.415 0 00-.793-.077.414.414 0 00.543.543zm4.146-1.425a1.25 1.25 0 111.392 2.08 1.25 1.25 0 01-1.393-2.08zm.854 1.425a.42.42 0 00.249-.466.415.415 0 00-.793-.077.414.414 0 00.543.543z" />
    </svg>
  );
}
export default SvgChatNew;
