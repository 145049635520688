import * as React from 'react';
function SvgInfoOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M16 2.667a13.334 13.334 0 019.428 22.761A13.334 13.334 0 012.667 16 13.334 13.334 0 0116 2.667zm0 1.666a11.668 11.668 0 100 23.335 11.668 11.668 0 000-23.335zm-.833 9.167a1.665 1.665 0 011.667 1.667v4.167a.833.833 0 00.833.833h.833a.833.833 0 010 1.666h-.833a2.5 2.5 0 01-2.5-2.5v-4.167h-.833a.833.833 0 010-1.666h.833zm0-4.667a1.335 1.335 0 01.511 2.566 1.336 1.336 0 01-1.818-.972 1.326 1.326 0 01.567-1.368c.219-.146.477-.225.741-.225z" />
    </svg>
  );
}
export default SvgInfoOutline;
