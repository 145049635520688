import React from 'react';
import styled from '@emotion/styled';
import { IconTypes } from '../../../@types/icons.types';

import Burger from './generated/Burger';
import Calendar from './generated/Calendar';
import CartEmpty from './generated/CartEmpty';
import Chat from './generated/Chat';
import ChatNew from './generated/ChatNew';
import Check from './generated/Check';
import Close from './generated/Close';
import ContextMenu from './generated/ContextMenu';
import Copy from './generated/Copy';
import Delete from './generated/Delete';
import Dropdown from './generated/Dropdown';
import Edit from './generated/Edit';
import Facebook from './generated/Facebook';
import Faq from './generated/Faq';
import Info from './generated/Info';
import InfoOutline from './generated/InfoOutline';
import Insta from './generated/Insta';
import LeftSlide from './generated/LeftSlide';
import LinkArrow from './generated/LinkArrow';
import Linkedin from './generated/Linkedin';
import Locate from './generated/Locate';
import Location from './generated/Location';
import Logout from './generated/Logout';
import Map from './generated/Map';
import MapOff from './generated/MapOff';
import MapPin from './generated/MapPin';
import Minus from './generated/Minus';
import Money from './generated/Money';
import Pdf from './generated/Pdf';
import Phone from './generated/Phone';
import Pickup from './generated/Pickup';
import Plus from './generated/Plus';
import Right from './generated/Right';
import RightSlide from './generated/RightSlide';
import Safe from './generated/Safe';
import Search from './generated/Search';
import Share from './generated/Share';
import Shield from './generated/Shield';
import SortDown from './generated/SortDown';
import Spinwheel from './generated/Spinwheel';
import Time from './generated/Time';
import Twitter from './generated/Twitter';
import Undo from './generated/Undo';
import User from './generated/User';
import Verfugbarkeit from './generated/Verfugbarkeit';
import ViewOn from './generated/ViewOn';
import Warning from './generated/Warning';
import Whatsapp from './generated/Whatsapp';
import XClose from './generated/XClose';
import Xing from './generated/Xing';
import Youtube from './generated/Youtube';
import Zoom from './generated/Zoom';
import Zustellung from './generated/Zustellung';
import Partner from './generated/Partner';

const icons: { [key in IconTypes]: React.ComponentType } = {
  'burger': Burger,
  'calendar': Calendar,
  'cart-empty': CartEmpty,
  'chat': Chat,
  'chat-new': ChatNew,
  'check': Check,
  'close': Close,
  'context-menu': ContextMenu,
  'copy': Copy,
  'delete': Delete,
  'dropdown': Dropdown,
  'edit': Edit,
  'facebook': Facebook,
  'faq': Faq,
  'info': Info,
  'info-outline': InfoOutline,
  'insta': Insta,
  'left-slide': LeftSlide,
  'link-arrow': LinkArrow,
  'linkedin': Linkedin,
  'locate': Locate,
  'location': Location,
  'logout': Logout,
  'map': Map,
  'map-off': MapOff,
  'map-pin': MapPin,
  'minus': Minus,
  'money': Money,
  'pdf': Pdf,
  'phone': Phone,
  'pickup': Pickup,
  'plus': Plus,
  'right': Right,
  'right-slide': RightSlide,
  'safe': Safe,
  'search': Search,
  'share': Share,
  'shield': Shield,
  'sort-down': SortDown,
  'spinwheel': Spinwheel,
  'time': Time,
  'twitter': Twitter,
  'undo': Undo,
  'user': User,
  'Verfugbarkeit': Verfugbarkeit,
  'view-on': ViewOn,
  'warning': Warning,
  'whatsapp': Whatsapp,
  'X-Close': XClose,
  'xing': Xing,
  'youtube': Youtube,
  'zoom': Zoom,
  'zustellung': Zustellung,
  'partner': Partner
};

export const IconComponent: React.FC<{
  icon: IconTypes;
  className?: string;
  color?: string;
  size?: string;
  data?: string;
}> = props => {
  const { color, icon: iconName, size = 'inherit', data = '', className } = props;
  const Icon = icons?.[iconName] ?? null;

  if (null === Icon) {
    return null;
  }

  return (
    <IconWrapper
      style={{ height: size, width: size }}
      fontSize={size}
      iconColor={color}
      className={`icon icon-${iconName} ${className ? className : ''}`}
      data-e2e-id={data}
    >
      <Icon />
    </IconWrapper>

  );
};

const IconWrapper = styled.span<{ fontSize: string; iconColor?: string }>`
  display: inline-block;
  font-size: ${(p): string => p.fontSize};
  line-height: ${(p): string => p.fontSize};
  color: ${(p): string | undefined => p.iconColor};

  svg {
    width: 100%;
    height: 100%;
    fill: ${(p): string | undefined => p.iconColor};
  }
`;

export const Icon = React.memo(IconComponent);
