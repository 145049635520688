import { useEffect, useState } from 'react';
import { AuthProcess } from '../../../../../@types/codegen/graphql';
import { useAuthProcessContent } from '../../../../../@types/codegen/page';

export const useAuthProcessContentHook = (): AuthProcess | null => {
  const [content, setContent] = useState<AuthProcess | null>(null);

  const { data } = useAuthProcessContent(() => {
    return {
      fetchPolicy: 'cache-first',
    };
  });

  useEffect(() => {
    setContent(data?.authProcess?.data?.attributes ?? null)
  }, [JSON.stringify(data)]);

  return content;
}
