import * as React from 'react';
function SvgTime(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M6.572 6.572a13.336 13.336 0 0118.856 0 13.336 13.336 0 010 18.856 13.336 13.336 0 01-18.856 0 13.336 13.336 0 010-18.856zM16 4.444a11.557 11.557 0 00-8.171 19.727 11.557 11.557 0 0016.342 0 11.557 11.557 0 000-16.342A11.557 11.557 0 0016 4.444zm0 6.223c.491 0 .889.398.889.889v4.077l5.295 5.296a.889.889 0 01-1.257 1.257l-5.555-5.556a.876.876 0 01-.259-.583l-.001-.046v-4.444c0-.491.398-.889.889-.889z" />
    </svg>
  );
}
export default SvgTime;
