import * as React from 'react';
function SvgDropdown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M15.892 19.727l-6.235-5.266c-.431-.364-.431-.953 0-1.313l1.036-.875c.431-.364 1.128-.364 1.554 0l4.42 3.733 4.42-3.733c.431-.364 1.128-.364 1.554 0l1.036.875c.431.364.431.953 0 1.313l-6.235 5.266c-.422.364-1.119.364-1.55 0z" />
    </svg>
  );
}
export default SvgDropdown;
