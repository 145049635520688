import { FC, useEffect, useState } from 'react';
import styles from './inputs.module.scss';
import classNames from 'classnames';
import { InputError } from './form.hook';
import { Icon } from '../icons/icon';
import { themeVariables } from '../styles/theme';

export interface IDateInputMobileProps {
  id: string;
  name: string;
  label: string;
  defaultValue?: string;
  value?: string;
  min?: string;
  max?: string;
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  suffix?: React.ReactNode;
  errors?: InputError[];
}

export const DateInputMobile: FC<IDateInputMobileProps> = (props) => {
  const {
    id,
    name,
    label,
    defaultValue = '',
    value,
    min,
    max,
    onChange,
    suffix,
    errors = []
  } = props;

  const [internalValue, setInternalValue] = useState<string>(defaultValue);

  useEffect(() => {
    if (value !== undefined && value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  return (
    <div className={classNames(styles.dateInputMobileContainer, {
      [styles.hasError]: !!errors.find(error => !error.isValid),
    })}>
      <label htmlFor={id} className={styles.dateInputMobileLabel}>
        {label}
      </label>
      <input
        className={classNames({
          [styles.dateInputMobile]: true,
        })}
        type={'date'}
        id={id}
        name={name}
        value={internalValue}
        min={min}
        max={max}
        onChange={(event): void => {
          const newValue = event.target.value;

          if (onChange) {
            onChange(newValue, event);
          }

          setInternalValue(newValue);
        }}
      />

      {suffix && (
        <span className={styles.dateInputMobileIcon} >
          {suffix}
        </span>
      )}

      {errors
        .filter(error => !error.isValid)
        .map((error) => (
          <div key={`${name}-${error.errorCode}`} className={styles.error}>
            <Icon icon={'warning'} size={'20px'} color={themeVariables.errorColor} />
            <span>{error.message}</span>
          </div>
        ))}

      {/* {(inputError && false === inputError.isValid) && (
        <div key={`${name}-${inputError.errorCode}`} className={styles.error}>
          <Icon icon={'warning'} size={'20px'} color={themeVariables.errorColor} />
          <span>{inputError.message}</span>
        </div>
      )} */}
    </div>
  );
};
