import React, { FC } from 'react';
import { spacingLeft } from '@digando/react-component-library';
import styled from '@emotion/styled';
import { WidgetDescription } from '../styles/widget-description';
import { WidgetContainer } from '../styles/widget-container';
import { mediaQueries } from '../../../styles/media-queries';
import { useTranslation } from 'next-i18next';
import { Icon } from '../../elements/Icons/Icon';
import { isFeatureFlagEnabled } from '../../../lib/environment';

export const SupportMobile: FC = () => {
  const [t] = useTranslation('common');

  if (!isFeatureFlagEnabled('isSupportEnabled')) {
    return null;
  }

  return (
    <SupportWidgetContainer>
      <SupportIcon icon={'phone'} size={'24px'} color={`var(--white)`} />
      <SupportText isHidden={false}>{t('support-hotline')} <a href={'tel:' + t('support-phone')}>{t('support-phone')}</a></SupportText>
    </SupportWidgetContainer>
  );
};

const SupportWidgetContainer = styled(WidgetContainer)`
  display: flex;
  background-color: var(--black);
  margin-left: 0;

  @media ${mediaQueries.tablet} {
    background-color: var(--primary-color);
    margin-top: 0px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  @media ${mediaQueries.tablet} {
    display: none;
  }
`;

const SupportIcon = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
`;

const SupportText = styled(WidgetDescription)`
  @media (max-width: 330px){
    font-size: 12px;
  }

  display: inline-block;
  vertical-align: middle;
  max-width: none;
  font-size: var(--font-size-text-small);
  line-height: 22px;
  color: var(--white);
  ${spacingLeft(1)};

  a {
    color: inherit;
    font: inherit;
    text-decoration: none;

    &:hover {
      color: var(--black);
    }
  }
`;

