import * as React from 'react';
function SvgRightSlide(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M16 2.667c7.366 0 13.333 5.968 13.333 13.333S23.365 29.333 16 29.333C8.634 29.333 2.667 23.365 2.667 16S8.635 2.667 16 2.667zm6.124 12.419l-7.285-7.285a1.286 1.286 0 00-1.823 0l-.914.914a1.286 1.286 0 000 1.823L17.564 16l-5.462 5.462a1.286 1.286 0 000 1.823l.914.914a1.286 1.286 0 001.823 0l7.285-7.285a1.292 1.292 0 000-1.828z" />
    </svg>
  );
}
export default SvgRightSlide;
