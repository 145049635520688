import React from 'react';
import { IconTypes } from './icons.types';

import Close from './generated/Close';
import Dropdown from './generated/Dropdown';
import Warning from './generated/Warning';
import Location from './generated/Location';

import styles from './icon.module.scss';
import { themeVariables } from './../styles/theme';

const icons: { [key in IconTypes]: React.ComponentType } = {
  close: Close,
  dropdown: Dropdown,
  warning: Warning,
  location: Location,
};

export const IconComponent: React.FC<{
  icon: IconTypes;
  className?: string;
  color?: string;
  size?: string;
  data?: string;
}> = (props) => {
  const {
    color = themeVariables.black,
    icon: iconName,
    size = 'inherit',
    data = '',
    className,
  } = props;
  const Icon = icons?.[iconName] ?? null;

  if (null === Icon) {
    return null;
  }

  return (
    <span
      style={{
        height: size,
        width: size,
        fontSize: size,
        color: color,
        fill: color,
        lineHeight: size,
      }}
      className={`${styles.icon} icon icon-${iconName} ${className ? className : ''}`}
      data-e2e-id={data}
    >
      <Icon />
    </span>
  );
};

export const Icon = React.memo(IconComponent);
