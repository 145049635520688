import { FC } from "react";
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

import dynamic from 'next/dynamic';

const PageFooterBig = dynamic(() => import('./page-footer-big/page-footer-big'));
const PageFooterSmall = dynamic(() => import('./page-footer-small/page-footer-small'));

export const PageFooter: FC = () => {
  switch (publicRuntimeConfig?.digandoFeatures?.footerVariant) {
  case 'small':
    return (
      <PageFooterSmall />
    );
  default:
  case 'big':
    return (
      <PageFooterBig />
    );
  }
}
