import { RefObject, useEffect } from 'react';

export const useHasUserScrolled = <T extends HTMLElement = HTMLElement>(ref: RefObject<T>, callback: {(): void}): void => {
  const handleFunctionAfterScroll = (e: Event): void => {
    if (ref.current && !ref.current.contains(e.target as HTMLInputElement)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleFunctionAfterScroll);

    return (): void => {
      document.removeEventListener('scroll', handleFunctionAfterScroll);
    };
  });
};
