import * as React from 'react';
function SvgContextMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M13.165 2.965a3.978 3.978 0 115.626 5.627 3.978 3.978 0 01-5.626-5.627zm2.813.568a2.244 2.244 0 100 4.488 2.244 2.244 0 000-4.488zM13.165 23.41a3.978 3.978 0 115.626 5.627 3.978 3.978 0 01-5.626-5.627zm2.813.568a2.244 2.244 0 100 4.488 2.244 2.244 0 000-4.488zm0-11.956a3.978 3.978 0 100 7.957 3.978 3.978 0 000-7.957zm-1.587 2.391a2.244 2.244 0 113.173 3.174 2.244 2.244 0 01-3.173-3.174z" />
    </svg>
  );
}
export default SvgContextMenu;
