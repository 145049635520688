import * as React from 'react';
function SvgPickup(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M22.673 29.333a1.67 1.67 0 01-1.667-1.667v-5h-.833a.833.833 0 01-.833-.833v-3.929a5.161 5.161 0 014.484-5.212 5.01 5.01 0 015.516 4.974v4.167c0 .46-.373.833-.833.833h-.833v5a1.67 1.67 0 01-1.667 1.667h-3.333zM21.84 21c.46 0 .833.373.833.833v5.833h3.333v-5.833c0-.46.373-.833.833-.833h.833v-3.333A3.34 3.34 0 0024 14.35a3.497 3.497 0 00-2.995 3.538v3.113h.833zM24.34 11a4.16 4.16 0 01-4.081-3.333h-.919a.833.833 0 010-1.666h.919a4.16 4.16 0 014.081-3.333c2.298 0 4.167 1.869 4.167 4.167s-1.869 4.167-4.167 4.167zm-2.353-3.333c.348.977 1.287 1.667 2.353 1.667s2.006-.69 2.353-1.667h-4.707zM26.693 6c-.348-.977-1.287-1.667-2.353-1.667s-2.006.69-2.353 1.667h4.707zM3.506 26a.833.833 0 010-1.666h7.819a.84.84 0 00.816-.659l1.933-9.031a2.51 2.51 0 012.444-1.977h1.155a.833.833 0 010 1.666H16.52a.838.838 0 00-.816.659l-1.933 9.031A2.514 2.514 0 0111.325 26H3.506zM4.756 29.333a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM11.423 29.333a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z" />
      <path d="M4.34 22.667A1.67 1.67 0 012.673 21v-5a1.67 1.67 0 011.667-1.667h5A1.67 1.67 0 0111.007 16v5a1.67 1.67 0 01-1.667 1.667h-5zm0-1.667h5v-5h-5v5z" />
    </svg>
  );
}
export default SvgPickup;
