import React, { FC } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { FontPrimarySemiBold, mediaQueries } from '@digando/react-component-library';
import { WidgetContainer } from '../styles/widget-container';
import { IWidgetDescriptionProps, WidgetDescription } from '../styles/widget-description';
import { useCartContext } from '../../../store/cart.context';
import { Icon } from '../../elements/Icons/Icon';

export const Basket: FC<IWidgetDescriptionProps> = ({ isHidden }) => {
  const [t] = useTranslation('common');
  const { cart } = useCartContext();

  return (
    <WidgetContainer role={'link'}>
      <Link
        href={'/basket'}
        passHref
        prefetch={false}
        aria-label={t('common:to-basket')}>

        <IconWrapper>
          <Icon size={'44px'} icon={'cart-empty'} color={'var(--primary-color)'}/>

          {cart?.items.length ? <BasketCount>{cart?.items.length ?? 0}</BasketCount> : ''}
        </IconWrapper>

        {cart?.items.length
          ? <WidgetDescription isHidden={isHidden}>{t('basket-items', { count: cart?.items.length ?? 0 })}</WidgetDescription>
          : ''
        }

      </Link>
    </WidgetContainer>
  );
};

const IconWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-bottom: -5px;

  span {
    position: relative;
    z-index: 1;
  }

  @media ${mediaQueries.tablet} {
    margin-bottom: -10px;
  }

  > .icon {
    width: 44px;
    height: 24px;
    transition: var(--text-hover-transition);

    svg {
      fill: var(--page-header-cart-color);
    }
  }
`;

const BasketCount = styled.div`
  ${FontPrimarySemiBold};
  width: 17px;
  height: 17px;
  border-radius: 50%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 11px;
  background-color: var(--secondary-color);
  font-size: 10px;
  line-height: 17px;
  color: var(--page-header-cart-font-color-contrast);
  text-align: center;
  transform: rotate(30deg);

  @media ${mediaQueries.laptop} {
    top: 0;
    left: 11px;
  }
`;
