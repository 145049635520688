import * as React from 'react';
function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M7.681 17.105a6.67 6.67 0 010-9.424 6.634 6.634 0 019.388 0 6.67 6.67 0 010 9.424 6.634 6.634 0 01-9.388 0zM2 12.393c0 2.756 1.093 5.4 3.039 7.349s4.585 3.044 7.336 3.044c2.203 0 4.333-.701 6.093-1.981.064.098.138.19.222.277l8.103 8.35a1.867 1.867 0 002.68-2.6l-8.103-8.35a1.847 1.847 0 00-.378-.3 10.405 10.405 0 00-1.281-13.138 10.364 10.364 0 00-14.672 0A10.406 10.406 0 002 12.393z" />
    </svg>
  );
}
export default SvgSearch;
