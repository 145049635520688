import styles from './options.module.scss';
import { AutocompleteItem, OptionItem } from './option-item';

export interface IOptionProps<DataType> {
  options: AutocompleteItem<DataType>[];
  setValue: (item: string) => void;
  onSelect: (option: AutocompleteItem<DataType>) => void;
}

export function Options<DataType>(props: IOptionProps<DataType>) {
  const { options, setValue, onSelect } = props;

  if (options) {
    return (
      <div className={styles.options}>
        {options.map((option, index) => (
          <OptionItem<DataType>
            key={index}
            option={option}
            setValue={setValue}
            onSelect={onSelect}
          />
        ))}
      </div>
    );
  }

  return null;
}
