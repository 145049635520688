'use client';

import { FC, MutableRefObject, useEffect, useState } from 'react';
import styles from './select.module.scss';
import { Icon } from '../../icons/icon';
import classNames from 'classnames';
import { Error } from './../errorInterface';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { SelectOption } from './select.types';

export interface SelectProps {
  id: string;
  name: string;
  label?: string;
  error?: Error & { message: string };
  disabled?: boolean;
  defaultValue?: string;
  isLoading?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  options: SelectOption[];
  onChange?: (value: string) => void;
  noOptionsText: string;
  noSelectionText: string;
  bordered?: boolean;
}

export const Select: FC<SelectProps> = (props) => {
  const {
    name,
    label = null,
    options,
    defaultValue = '',
    error = null,
    disabled = false,
    autoComplete,
    autoFocus = false,
    id,
    onChange,
    noOptionsText,
    noSelectionText,
    bordered = false,
  } = props;
  const hasLabel = null !== label;

  const [value, setValue] = useState<string>(defaultValue);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const currentValue = options.find((option) => option.value === value);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const refSelectorContainer: MutableRefObject<HTMLDivElement> | null = useDetectClickOutside({
    onTriggered: (event): void => {
      setIsOpen(false);
    },
  });

  useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  /**
   * Trigger onChange event when value changes.
   */
  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  /**
   * Handle keyboard events for selector.
   * This will allow to navigate through options with arrow keys.
   */
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    // on arrow down select first option in selector container
    const handleKeyDown = (event: KeyboardEvent): void => {
      let nextIndex = null;

      if (event.key === 'ArrowDown') {
        nextIndex = null !== focusedIndex ? focusedIndex + 1 : 0;

        if (nextIndex >= options.length) {
          nextIndex = 0;
        }
      } else if (event.key === 'ArrowUp') {
        nextIndex = null !== focusedIndex ? focusedIndex - 1 : options.length - 1;

        if (nextIndex < 0) {
          nextIndex = options.length - 1;
        }
      }

      // If we have a next index, focus the option
      if (null !== nextIndex) {
        let option = refSelectorContainer?.current?.querySelector<HTMLDivElement>(`[data-option-id="${nextIndex}"]`);

        // If we can't find the option, try to find the first option.
        if (!option) {
          nextIndex = 0;

          option = refSelectorContainer?.current?.querySelector<HTMLDivElement>(`[data-option-id="${nextIndex}"]`);
        }

        if (option) {
          option.focus();

          setFocusedIndex(nextIndex);
        }
      } else {
        setFocusedIndex(null);
      }

      event.preventDefault();
    };

    refSelectorContainer?.current.addEventListener('keydown', handleKeyDown);

    return () => {
      // reference can already be detached and not accessible
      if (refSelectorContainer?.current) {
        refSelectorContainer?.current?.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [refSelectorContainer?.current, focusedIndex, isOpen]);

  return (
    <div ref={refSelectorContainer}>
      <div id={id} className={classNames({
        [styles.selectContainer]: true,
        [styles.bordered]: bordered,
        [styles.hasLabel]: hasLabel,
      })}>
        <div className={styles.select} onClick={() => {
          setIsOpen(!isOpen);
        }}>
          {hasLabel && (
            <label htmlFor={id} className={styles.selectLabel} onClick={() => {
              setIsOpen(true);
            }}>
              {label}
            </label>
          )}
          {currentValue && (
            <div className={styles.currentOption}>
              <span className={classNames({
                [styles.optionName]: true,
                [styles.hasDescription]: !!currentValue.description,
              })}>{currentValue.name}</span>
              {currentValue.description && (
                <span className={styles.optionDescription}>{currentValue.description}</span>)}
            </div>
          )}

          {0 === options.length && (
            <div className={classNames({
              [styles.currentOption]: true,
              [styles.noSelection]: true,
            })}>
              <span className={styles.optionName}>{noOptionsText}</span>
            </div>
          )}

          {(0 < options.length && !currentValue) && (
            <div className={classNames({
              [styles.currentOption]: true,
              [styles.noSelection]: true,
            })}>
              <span className={styles.optionName}>{noSelectionText}</span>
            </div>
          )}

          <span className={styles.dropdownIcon}>
            <Icon icon={'dropdown'} />
          </span>
        </div>

        <div className={classNames({
          [styles.selector]: true,
          [styles.isOpen]: isOpen,
        })}>
          {options.map((option, index) => (
            <div
              key={`${id}-option-${option.key}`}
              data-option-id={index}
              id={`option-${option.key}`}
              className={classNames({
                [styles.option]: true,
                [styles.optionSelected]: option.value === value,
              })}
              tabIndex={0}
              onClick={() => {
                setValue(option.value);
                setIsOpen(false);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setValue(option.value);
                  setIsOpen(false);
                }
              }}
            >
              <span className={classNames({
                [styles.optionName]: true,
                [styles.hasDescription]: !!option.description,
              })}>{option.name}</span>
              {option.description && (<span className={styles.optionDescription}>{option.description}</span>)}
            </div>
          ))}
        </div>

        {error && !error.isValid && (
          <div key={`${name}-${error.key}`} className={styles.errorContainer}>
            <Icon
              icon={'warning'}
              size={'18px'}
              color={'var(--color-error)'}
            />
            <span>{error.message}</span>
          </div>
        )}

        <select
          autoFocus={autoFocus}
          id={id}
          name={name}
          autoComplete={autoComplete}
          value={value}
          disabled={disabled}
          onChange={(event): void => {
            const newValue = event.target.value;
            setValue(newValue);
          }}
        >
          {defaultValue === '' && <option key='' value='' disabled></option>}
          {options.map((option) => (
            <option key={option.key} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
