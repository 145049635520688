import * as React from 'react';
function SvgRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M21.805 16.399l-8.5 8.5a1.495 1.495 0 01-2.119 0l-1.412-1.413a1.493 1.493 0 010-2.119l6.025-6.025-6.025-6.025a1.493 1.493 0 010-2.119l1.406-1.425a1.493 1.493 0 012.119 0l8.5 8.5c.594.587.594 1.537.006 2.125z" />
    </svg>
  );
}
export default SvgRight;
