import { FC } from 'react';
import styles from './loader.module.scss';
import classNames from 'classnames';

type LoaderProps = {
  invert?: boolean;
  small?: boolean;
};

export const Loader: FC<LoaderProps> = ({invert = false, small = false}) => {
  return (
    <div className={classNames({
        [styles.digandoLoaderBox]: true,
        [styles.digandoLoaderSmall]: small,
    })}>
      <span className={classNames({
        [styles.digandoLoader]: true,
        [styles.digandoLoaderInvert]: invert,
      })}>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </span>
    </div>
  );
};
