import { ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import { SessionProviderProps, useSession, SessionProvider as SessionProviderWrapper } from "next-auth/react";
import { useEffect } from "react";
import { getApolloClient } from "../lib/apollo-client/apollo-client";

let interval: NodeJS.Timeout;

interface ISessionProviderProps extends SessionProviderProps {
  apolloState?: NormalizedCacheObject
}

const SessionProvider: React.FC<ISessionProviderProps> = (props) => {
  return (
    <SessionProviderWrapper
      session={props.session}
    >
      <SessionProviderAction apolloState={props.apolloState}>
        {props.children}
      </SessionProviderAction>
    </SessionProviderWrapper>
  );
};

const SessionProviderAction: React.FC<{children: React.ReactNode; apolloState?: ISessionProviderProps['apolloState']}> = ({children, apolloState}) => {
  const { update, data: session } = useSession();
  const apolloClient = getApolloClient({ initialState: apolloState, session: session as SessionType });

  useEffect(() => {
    if(session as SessionType) {
      const remainedTime = ((session as SessionType)?.expires_at ?? 0) - Math.round(Date.now() / 1000);
      const timeout = (remainedTime - 60) * 1000;
      interval = setTimeout(() => {
        //update();
      }, timeout);
    }
    return () => clearInterval(interval)
  }, [update]);

  useEffect(() => {
    const visibilityHandler = () => {
      if (document.visibilityState === "visible") {
        update();
      }
      else if (document.visibilityState === "hidden") {
        clearInterval(interval);
      }
    }

    window.addEventListener("visibilitychange", visibilityHandler, false)
    return () =>
      window.removeEventListener("visibilitychange", visibilityHandler, false)
  }, [update]);
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default SessionProvider;
