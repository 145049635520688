// @todo: Props for labels
// @todo: Use rotate functionality from Spin, with custom `indicator` prop
import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  FontPrimaryRegular,
  mediaQueries,
  px2vw,
  spacingRight,
} from '@digando/react-component-library';
import { checkAvailability } from '../../helper/check-availability';
import { useProductSearchValues } from '../../store/recoil/product-search';
import { Icon } from '../elements/Icons/Icon';

export interface IAvailabilityCheckProps {
  lastUpdateLabel: string;
  time: string;
  timeLabel: string;
  oClockLabel: string;
  onRequestUpdate: () => void;
  availabilityLabel: string;
  loadedDateTime: string | null;
}

export const AvailabilityCheck: FC<IAvailabilityCheckProps> = props => {
  const { lastUpdateLabel, time, timeLabel, oClockLabel, onRequestUpdate, availabilityLabel, loadedDateTime } = props;
  const [rotate, setRotate] = React.useState<boolean>(false);
  const {isResultsLoading} = useProductSearchValues();

  const [showAvailabilityHint, setShowAvailabilityHint] = useState<boolean>(false);

  useEffect(() => {
    const checkAvailabilityHint = (): void => {
      if (null === loadedDateTime) {
        return;
      }

      setShowAvailabilityHint(checkAvailability(loadedDateTime));
    };

    checkAvailabilityHint();
    const interval = setInterval(checkAvailabilityHint, 60000);

    return (): void => clearInterval(interval);
  }, [loadedDateTime]);

  return (
    <>
      <AvailabilityCheckWrapper data-e2e-component={'AvailabilityCheck'} data-e2e-date={time}>
        <StyledSync
          onClick={(): void => {
            onRequestUpdate();
            setRotate(true);

            setTimeout(() => {
              setRotate(false);
            }, 1000);
          }}
        >
          <Icon
            icon={'undo'}
            size={'24px'}
            className={(rotate || isResultsLoading) ? 'rotate' : ''}
            color={`inherit`}
          />
        </StyledSync>
        <div>
          <StyledTime>
            {lastUpdateLabel} {time} {oClockLabel}
            <StyledTimeLabel>
              {false === showAvailabilityHint
                ? timeLabel
                : <a href={'#'} onClick={(event: React.MouseEvent): void => {
                  event.preventDefault();
                  onRequestUpdate();
                  setRotate(true);

                  setTimeout(() => {
                    setRotate(false);
                  }, 1000);
                }}>
                  {availabilityLabel}
                </a>}
            </StyledTimeLabel>
          </StyledTime>
        </div>
      </AvailabilityCheckWrapper>
    </>
  );
};

const AvailabilityCheckWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const StyledSync = styled.div`
  margin-right: ${px2vw(10)};
  cursor: pointer;

  .rotate {
    transition: transform 1s;
    transform: rotate(-360deg);
  }
`;

const StyledTime = styled.div`
  font-size: var(--font-size-text-small);
  line-height: 20px;
  ${FontPrimaryRegular};
  ${spacingRight(2)};
  color: inherit;
`;

const StyledTimeLabel = styled.div`
  a {
    color: inherit;
  }

  @media ${mediaQueries.tablet} {
    ${spacingRight(0)};
  }
`;
