import { atom } from 'recoil';
import { CountriesQuery } from './../../@types/codegen/graphql';

export const countriesState = atom<CountriesQuery['countries'] | null>({
  key: 'countries',
  default: null,
});

export const isLoaderLayoutActiveState = atom<boolean>({
  key: 'isLoaderLayoutActive',
  default: false,
});

export const isPageInitializationStartedState = atom<boolean>({
  key: 'isPageInitializationStarted',
  default: false,
});