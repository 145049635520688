import React, { FC } from 'react';
import SvgPlatformLogo from '../../../svg/PlatformLogo';
import SvgPlatformLogoShort from '../../../svg/PlatformLogoShort';

export const PageHeaderLogo: FC<{ isShortLogo: boolean; }> = ({ isShortLogo }) => {
  if (isShortLogo) {
    return (<SvgPlatformLogoShort />);
  }

  return (
    <SvgPlatformLogo />
  );
};
