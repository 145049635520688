import * as React from 'react';
function SvgPdf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M5.667 4.833a.833.833 0 00-.833.833v21.667a.833.833 0 00.833.833H9a.833.833 0 010 1.666H5.667a2.5 2.5 0 01-2.5-2.5V5.665a2.5 2.5 0 012.5-2.5h11.81a2.492 2.492 0 011.768.733l6.523 6.523a2.503 2.503 0 01.732 1.769v5.142a.833.833 0 01-1.666 0V12.19a.832.832 0 00-.243-.589l-6.523-6.523a.842.842 0 00-.59-.245H5.668z" />
      <path d="M17.333 3.167c.46 0 .833.373.833.833v6.667a.833.833 0 00.833.833h6.667a.833.833 0 010 1.666h-6.667a2.5 2.5 0 01-2.5-2.5V3.999c0-.46.373-.833.833-.833zM12.333 19.833c.46 0 .833.373.833.833v8.333a.833.833 0 01-1.666 0v-8.333c0-.46.373-.833.833-.833z" />
      <path d="M11.5 20.667c0-.46.373-.833.833-.833h.838a3.333 3.333 0 110 6.666h-.833a.833.833 0 010-1.666h.833a1.667 1.667 0 000-3.334h-.838a.833.833 0 01-.833-.833zM18.167 20.667c0-.46.373-.833.833-.833A4.167 4.167 0 0123.166 24v1.667A4.167 4.167 0 0119 29.833a.833.833 0 01-.833-.833v-8.333zm1.666.976v6.381a2.499 2.499 0 001.666-2.357V24a2.5 2.5 0 00-1.666-2.357zM27.333 21.5a.833.833 0 00-.833.833V29a.833.833 0 01-1.666 0v-6.667a2.5 2.5 0 012.5-2.5h1.667a.833.833 0 010 1.666h-1.667z" />
      <path d="M24.833 25.667c0-.46.373-.833.833-.833h2.5a.833.833 0 010 1.666h-2.5a.833.833 0 01-.833-.833z" />
    </svg>
  );
}
export default SvgPdf;
