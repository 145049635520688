import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

export const getInMemoryCache = (): InMemoryCache => {
  return new InMemoryCache({
    typePolicies: {
      ProductSearchResults: {
        keyFields: [],
      },
      ProductSearchFilterResults: {
        keyFields: [],
      },
      Places: {
        fields: {
          // Always prefer fresh data from location search
          data: {
            merge: false,
          },
        },
      },
    },
    dataIdFromObject(responseObject): string | undefined {
      switch (responseObject.__typename) {
      case 'Location':
        if (responseObject?.distance) {
          return `Location:${responseObject.lat}_${responseObject.lng}_${responseObject.distance}`;
        }

        return `Location:${responseObject.lat}_${responseObject.lng}`;
      default:
        return defaultDataIdFromObject(responseObject);
      }
    }
  });
}
