import * as React from 'react';
function SvgLocate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M17.667 2.667h-3.333v3.472a10.008 10.008 0 00-8.195 8.195H2.667v3.333h3.472a10.01 10.01 0 008.195 8.195v3.472h3.333v-3.472a10.01 10.01 0 008.195-8.195h3.472v-3.333h-3.472a10.008 10.008 0 00-8.195-8.195V2.667zM24.333 16a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0z" />
    </svg>
  );
}
export default SvgLocate;
