import { FC } from 'react';
import { Icon } from '@digando/react-component-library';
import { WidgetDescription } from '../../styles/widget-description';
import { useAuthContext } from '../../../../store/auth.context';
import { DrawerStep } from '../../../sidebar/interfaces/drawer.interfaces';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ProfileItemProps } from './profile-item';
import styles from './profile-item.module.scss';

export const LoggedOutProfileItem: FC<ProfileItemProps> = ({ small }) => {
  const router = useRouter();
  const [t] = useTranslation(['common']);
  const { setRedirectLink, setIsSignInVisible, setSignInStep } = useAuthContext();

  const signInRedirect = (event: React.MouseEvent, link: string): void => {
    event.preventDefault();
    setRedirectLink(link);
    setSignInStep(DrawerStep.SIGN_IN);
    setIsSignInVisible(true);
  };

  return (
    <>
      <button
        onClick={(event): void => signInRedirect(event, router.asPath)}
        data-e2e-id={'DigandoSignInHeaderButton'}
        aria-label={'Login'}
      >
        <Icon icon={'user'} size={'30px'} color={`var(--page-header-font-color)`} />
        <WidgetDescription isHidden={false} className={styles.widgetDescription}>
          {false === small && t('common:sign-in')}
        </WidgetDescription>
      </button>
    </>
  );
};
