import { createHttpLink } from '@apollo/client';
import getConfig from 'next/config';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from "@apollo/client/link/retry";
import { DigandoTestingMode } from '../../constants/cookies';
import { isBrowser } from '../environment';
import { parseCookies } from 'nookies';
import { getSession } from 'next-auth/react';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

if ('true' === serverRuntimeConfig.api?.features?.retry?.enabled) {
  console.info('RetryLink feature enabled with options: ', serverRuntimeConfig.api?.features);
}

export const getApolloLink = (session: SessionType) => {
  const uri = serverRuntimeConfig?.api?.url ?? publicRuntimeConfig.apiUrl;

  /** Setup basic HTTP link */
  const httpLink = createHttpLink({
    uri,
  });

  /** Setup Auth link */
  const authLink = setContext(async (_, context) => {
    const crsAffiliateId = publicRuntimeConfig?.crsAffiliateId ?? 'default';
    const cookies = parseCookies(context);

    let token: string | null = context?.accessToken ?? null;
    let testingMode = context?.cookies?.[DigandoTestingMode] ?? context.testingmode ?? 'false';

    testingMode = cookies?.[DigandoTestingMode] ?? 'false';

    // Only set token if we are in the browser
    if (true === isBrowser()) {
      const nextAuthSession = await getSession();

      token = nextAuthSession?.accessToken ?? null;
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...context.headers,
        authorization: token ? `Bearer ${token}` : '',
        testingMode,
        'x-digando-crs-affiliate-id': crsAffiliateId,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        // eslint-disable-next-line no-console
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
        ),
      );

    // eslint-disable-next-line no-console
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  /** Build link */
  let link = errorLink.concat(authLink.concat(httpLink));

  if ('true' === serverRuntimeConfig.api?.features?.retry?.enabled) {
    const retryLink = new RetryLink({
      attempts: {
        max: parseInt(serverRuntimeConfig.api?.features?.retry?.maxRetries ?? '5'),
      }
    });

    link = retryLink.concat(link);
  }

  return link;
};
