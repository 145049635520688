import React, { useEffect } from 'react';
import { createContext, FC, useContext, useState } from 'react';
import { CategoryFragment, CategoryTreeFragment } from '../@types/codegen/graphql';
import { flatCategories } from '../helper/flat-categories';
import Bugsnag from '@bugsnag/js';
import { useStaticSearch } from '../@types/codegen/page';

interface ICategoriesContextProps {
  categories: CategoryFragment[];
  rootCategory: CategoryFragment | null;
  firstLevelCategories: CategoryTreeFragment[];
}

const NavigationContext = createContext<ICategoriesContextProps>({
  categories: [],
  rootCategory: null,
  firstLevelCategories: [],
});

export const CategoriesWrapper: FC<{ children: React.ReactNode; }> = ({ children }) => {
  const {data} = useStaticSearch(() => {
    return {
      fetchPolicy: 'cache-only',
    }
  });
  const initialCategories = data?.categories?.data ?? [];

  if (0 === (initialCategories?.length ?? 0)) {
    console.error('No categories has been loaded');
    Bugsnag.notify({
      name: 'Category Error',
      message: 'No categories has been loaded'
    });
  }

  const newRootCategory = initialCategories[0] as CategoryTreeFragment;

  const [rootCategory, setRootCategory] = useState<CategoryTreeFragment | null>(newRootCategory);
  const [categories, setCategories] = useState<CategoryTreeFragment[]>(flatCategories(newRootCategory));
  const [firstLevelCategories, setFirstLevelCategories] = useState<CategoryTreeFragment[]>(newRootCategory?.children as CategoryTreeFragment[] ?? []);

  useEffect(() => {
    const data = initialCategories;

    if (data) {
      const newRootCategory = data[0] as CategoryTreeFragment;

      setRootCategory(newRootCategory);
      setFirstLevelCategories(newRootCategory?.children as CategoryTreeFragment[] ?? []);
      setCategories(flatCategories(newRootCategory));
    }
  }, [JSON.stringify(initialCategories)]);

  return (
    <NavigationContext.Provider value={{
      categories,
      rootCategory,
      firstLevelCategories,
    }}>
      {children}
    </NavigationContext.Provider>
  );
};

export function useCategoriesContext(): ICategoriesContextProps {
  return useContext(NavigationContext);
}
