import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const themeName = publicRuntimeConfig.theme ?? 'default';
const currentTheme = await import((`../../../../../libs/ui/themes/src/${themeName}/themed-variables`));

export const theme = {
  name: themeName,
  ...currentTheme.themeVariables,
};
