import React, { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { useMe } from '../@types/codegen/page';
import Bugsnag from '@bugsnag/js';
import { useRouter } from 'next/router';
import { DrawerStep } from '../components/sidebar/interfaces/drawer.interfaces';
import { useSession } from 'next-auth/react';
import { useSetRecoilState } from 'recoil';
import { meOrganizationState, meUserState } from './recoil/auth.store';

interface IAuthContextProps {
  refetch: () => void;
  isSignInVisible: boolean;
  signInStep: DrawerStep;
  setIsSignInVisible: Dispatch<SetStateAction<boolean>>;
  setSignInStep: Dispatch<SetStateAction<DrawerStep>>;
  loading: boolean;
  redirectLink: string | null;
  setRedirectLink: Dispatch<SetStateAction<string | null>>;
  setHasLoginError: Dispatch<SetStateAction<false | string>>;
  hasLoginError: false | string;
}

export interface IAuthData {
  email: string;
  password: string;
}

export enum LoginStates {
  LOGGED_IN = 'authenticated',
  LOGGED_OUT = 'unauthenticated',
  LOADING = 'loading',
}

const AuthContext = createContext<IAuthContextProps>({
  loading: false,
  isSignInVisible: false,
  signInStep: DrawerStep.SIGN_IN,
  redirectLink: null,
  hasLoginError: false,
  // eslint-disable-next-line no-console
  refetch: () => console.error('Auth Provider not initialized.'),
  // eslint-disable-next-line no-console
  setIsSignInVisible: () => console.error('Auth Provider not initialized.'),
  // eslint-disable-next-line no-console
  setSignInStep: () => console.error('Auth Provider not initialized.'),
  // eslint-disable-next-line no-console
  setRedirectLink: () => console.error('Auth Provider not initialized.'),
  // eslint-disable-next-line no-console
  setHasLoginError: (hasError: SetStateAction<false | string>) => console.error('Auth Provider not initialized.'),
});

interface AuthWrapperProps {
  children: React.ReactNode;
}

export const AuthWrapper: FC<AuthWrapperProps> = ({children}) => {
  const { data: session } = useSession();
  const [isSignInVisible, setIsSignInVisible] = useState<boolean>(false);
  const [signInStep, setSignInStep] = useState<DrawerStep>(DrawerStep.NONE);

  const { data: meData, refetch: refetchFromApi, error, loading } =  useMe(() => {
    return {
      skip: !session?.accessToken,
      fetchPolicy: 'cache-and-network', // first use ssr loaded data, then fetch new always from network
      nextFetchPolicy: 'network-only'
    };
  });

  const setMeUser = useSetRecoilState(meUserState);
  const setMeOrganization = useSetRecoilState(meOrganizationState);

  const [redirectLink, setRedirectLink] = useState<IAuthContextProps['redirectLink']>(null);
  const [hasLoginError, setHasLoginError] = useState<false | string>(false);

  const router = useRouter();

  const refetch = () => {
    return refetchFromApi({});
  }

  useEffect(() => {
    if ('email-verified' === router?.query?.uid) {
      setSignInStep(DrawerStep.SIGN_UP);
      setIsSignInVisible(true);
    }
  }, []);

  useEffect(() => {
    const newUserData = meData?.KeycloakUserService_me ?? null;
    const newOrganizationData = meData?.OrganizationService_OrganizationForUser ?? null;

    setMeUser(newUserData);
    setMeOrganization(newOrganizationData);

    Bugsnag.setUser(newUserData?.id, newUserData?.email);
  }, [JSON.stringify(meData)]);

  useEffect(() => {
    if (error) {
      setMeUser(null);
      setMeOrganization(null);
    }
  }, [error]);

  return (
    <AuthContext.Provider value={{
      refetch,
      loading,
      redirectLink,
      setRedirectLink,
      isSignInVisible,
      setIsSignInVisible,
      signInStep,
      setSignInStep,
      hasLoginError,
      setHasLoginError,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext(): IAuthContextProps {
  return useContext(AuthContext);
}
