import { DigandoAffiliateIdCookieName } from '../constants/cookies';
import nookies from 'nookies';

export const useWastebox = (): { affiliateId: string; } | undefined => {
  // Check if user has an affiliate cookie set and create affiliateId object for registration
  const cookies = nookies.get();
  const affiliateId = cookies[DigandoAffiliateIdCookieName];

  let affiliateData: { affiliateId: string } | undefined;

  if (affiliateId) {
    affiliateData = {
      affiliateId: affiliateId,
    };
  }

  return affiliateData;
};
