import { CookieSerializeOptions } from 'cookie';
import { Environments } from './environments';

export const DigandoAffiliateIdCookieName = 'DIGANDO_AFFILIATE_ID';
export const DigandoAffiliateFirstVisitCookieName = 'DIGANDO_AFFILIATE_IS_FIRST_VISIT';
export const DigandoTestingMode = 'DIGANDO_TESTING_MODE';
export const DigandoCountryCode = 'DIGANDO_COUNTRY_CODE';

/**
 * Default cookie settings
 */
export const DigandoCookieSettings: CookieSerializeOptions = {
  path: '/',
  maxAge: 7 * 24 * 60 * 60,
  sameSite: 'strict',
  secure: Environments.PRODUCTION === process.env.NODE_ENV,
};
