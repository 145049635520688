export enum DrawerStep {
  NONE = 'none',
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  FORGOT_PASSWORD = 'forgotPassword',
}

export enum SignUpStep {
  SIGN_UP_COMPANY = 'signUpCompany',
  SIGN_UP_USER = 'signUpUser',
  SIGN_UP_FINISH = 'signUpFinish',
  SIGN_UP_DONE = 'signUpDone',
}
