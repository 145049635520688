import * as React from 'react';
import getConfig from 'next/config';
import NextImage from 'next/image';

const { publicRuntimeConfig } = getConfig();
const themeName = publicRuntimeConfig.theme ?? 'default';
const LogoSVG = await import((`../../../../../libs/ui/themes/src/${themeName}/logo.svg`));

function SvgPlatformLogo() {

  return (
    <NextImage
      src={LogoSVG.default.src}
      priority={true}
      unoptimized={true}
      alt={"Logo"}
      title={"Logo"}
      width={LogoSVG.default.width}
      height={LogoSVG.default.height}
    />
  );
}

export default SvgPlatformLogo;
