import React, { FC } from 'react';

import { FontPrimaryRegular, FontPrimarySemiBold, Icon } from '@digando/react-component-library';
import styled from '@emotion/styled';

import { WidgetDescription } from '../styles/widget-description';
import { WidgetContainer } from '../styles/widget-container';
import { mediaQueries } from '../../../styles/media-queries';
import { useTranslation } from 'next-i18next';
import { isFeatureFlagEnabled } from '../../../lib/environment';

interface ISupportProps {
  color?: string;
  iconSize?: string;
  workHours?: JSX.Element | string;
  fontWeightBold?: boolean;
}

/**
 * @--support-text-color-override : will override the color of the support text and icon.
 */
export const Support: FC<ISupportProps> = ({ ...props }) => {
  const [t] = useTranslation('common');

  if (!isFeatureFlagEnabled('isSupportEnabled')) {
    return null;
  }

  return (
    <SupportWidgetContainer color={props.color ?? 'var(--page-header-font-color)'}>
      <Icon icon={'phone'} size={props.iconSize ?? '30px'} />
      <SupportText isHidden={false} fontWeightBold={props.fontWeightBold ?? false} color={props.color ?? `var(--page-header-font-color)`}>
        {t('support-hotline')}&nbsp;<a href={'tel:' + t('support-phone')}>{t('support-phone')}</a>
        <WorkHoursWrapper>{props.workHours}</WorkHoursWrapper>
      </SupportText>
    </SupportWidgetContainer>
  );
};

const SupportWidgetContainer = styled(WidgetContainer)`
  --support-font-color: var(--support-text-color-override, ${(p): string => (p.color ? p.color : `var(--black)`)});

  display: none;

  svg {
    fill: var(--support-font-color);
  }

  @media ${mediaQueries.tablet} {
    display: flex;
  }

  @media print {
    a[href]:after {
      content: none !important;
    }
  }
`;

const WorkHoursWrapper = styled.div`
  ${FontPrimaryRegular};
`;

const SupportText = styled(WidgetDescription)<{ fontWeightBold: boolean; color: string; }>`
  max-width: none;
  ${(p) => (p.fontWeightBold ? FontPrimarySemiBold : FontPrimaryRegular)};
  color: var(--support-font-color);

  a {
    color: var(--support-font-color);
    font: inherit;
    text-decoration: none;
  }
`;
