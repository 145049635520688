import * as React from 'react';
function SvgSpinwheel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M16.833 2.667a.833.833 0 00-1.666 0v4.444a.833.833 0 001.666 0V2.667zm0 22.222a.833.833 0 00-1.666 0v4.444a.833.833 0 001.666 0v-4.444zM5.983 5.982a.835.835 0 011.179 0l3.143 3.143a.833.833 0 11-1.178 1.179L5.984 7.161a.834.834 0 010-1.178zm16.891 15.714a.835.835 0 00-1.179 1.179l3.143 3.142a.835.835 0 001.179-1.179l-3.143-3.142zM1.833 16c0-.46.373-.833.833-.833H7.11a.833.833 0 010 1.666H2.666A.833.833 0 011.833 16zm23.056-.833a.833.833 0 000 1.666h4.445a.833.833 0 000-1.666h-4.445zm-14.585 6.53a.835.835 0 010 1.179l-3.143 3.142a.834.834 0 01-1.178-1.179l3.143-3.142a.834.834 0 011.178 0zM26.017 7.161a.833.833 0 10-1.178-1.179l-3.143 3.143a.835.835 0 001.179 1.179l3.143-3.143z" />
    </svg>
  );
}
export default SvgSpinwheel;
