import styled from '@emotion/styled';
import React, { FC, useEffect, useState } from 'react';

const duration = 0.3; // in seconds.

export interface IBackdrop {
  isOpen: boolean;
  isVisible: boolean;
}

export interface IBackdropProps {
  isOpen: boolean;
  onClick?: () => void;
}

export const Backdrop: FC<IBackdropProps> = ({ isOpen, onClick }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => setIsVisible(isOpen), []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (true === isOpen) {
      setIsVisible(true);
    } else {
      timeout = setTimeout(() => setIsVisible(false), duration * 100);
    }

    return (): void => {
      clearTimeout(timeout);
    };
  }, [isOpen, setIsVisible]);

  return (
    <>
      <BackdropWrapper isOpen={isOpen} isVisible={isVisible} onClick={onClick}/>
    </>
  );
};

const BackdropWrapper = styled.div<IBackdrop>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 52, 52, 0.5);
  opacity: ${(p): number => p.isOpen ? 1 : 0};
  pointer-events: ${(p): string => p.isVisible ? 'inherit' : 'none'};
  z-index: 102;
  transition: all ${duration}s ease;
`;
