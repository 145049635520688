import * as React from 'react';
function SvgShare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M19.964 5.798a4.998 4.998 0 017.072 0 4.998 4.998 0 010 7.072 4.998 4.998 0 01-7.072 0 5.014 5.014 0 01-.757-.972l-5.81 2.26a5.002 5.002 0 01-.115 2.47l6.181 3.09a4.998 4.998 0 017.574-.586 4.998 4.998 0 010 7.071 5 5 0 01-8.318-4.994l-6.181-3.091a4.998 4.998 0 01-7.573.585 4.998 4.998 0 010-7.072 4.998 4.998 0 017.072 0c.294.294.548.621.758.972l5.81-2.26a5.004 5.004 0 011.361-4.547zM23.5 6a3.333 3.333 0 100 6.667A3.333 3.333 0 0023.5 6zm-15 5.833a3.333 3.333 0 100 6.667 3.333 3.333 0 000-6.667zm15 7.5a3.333 3.333 0 100 6.667 3.333 3.333 0 000-6.667z" />
    </svg>
  );
}
export default SvgShare;
