import * as React from 'react';
function SvgMap(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M20.869 11.934l.227.078 6.667 2.667a2.502 2.502 0 011.559 2.073l.012.249V27.39a1.674 1.674 0 01-.73 1.386 1.67 1.67 0 01-1.363.234l-.194-.064-6.571-2.629a.83.83 0 00-.497-.038l-.122.038-7.096 2.839a2.537 2.537 0 01-.692.168l-.236.011-.236-.011a2.572 2.572 0 01-.466-.089l-.226-.078-6.667-2.667a2.502 2.502 0 01-1.559-2.073l-.012-.249V13.783a1.662 1.662 0 01.73-1.386 1.67 1.67 0 011.364-.233l.193.064 6.57 2.624a.86.86 0 00.205.053l.106.007a.834.834 0 01.823.71l.009.123v11.654l6.667-2.663V13.769l-.482.194a.832.832 0 01-.741-1.487l.121-.06 1.007-.404a2.493 2.493 0 011.63-.078zM21 13.768v10.967l.095.034 6.572 2.629-.001-10.397a.84.84 0 00-.412-.72l-.112-.055-6.143-2.458zm-16.667.005v10.393a.834.834 0 00.412.719l.112.055L11 27.397V16.43l-.095-.032-6.572-2.625zM15.167 2.667a4.167 4.167 0 01.834 8.248L16 20.166a.833.833 0 01-1.657.123l-.009-.123.001-9.251a4.163 4.163 0 01-3.333-4.082 4.167 4.167 0 014.166-4.166zm0 1.666a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" />
    </svg>
  );
}
export default SvgMap;
