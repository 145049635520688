import dayjs from 'dayjs';

export const checkAvailability = (loadedDateTime: string): boolean => {
  const tzOffset = (new Date()).getTimezoneOffset();
  const loaded = parseInt(dayjs.utc(loadedDateTime).format('YYYYMMDDhhmm'));
  const now = parseInt(dayjs().add(tzOffset, 'minutes').format('YYYYMMDDhhmm'));

  // Due to rounding- or network-issues it can happen that minute value is not accurate.
  // To prevent issues on frontend, we add a small tolerance.
  const acceptableTime = now - 1;

  return acceptableTime > loaded;
};
