import { MeDetailsFragment, OrganizationResponseFragment } from '../../@types/codegen/graphql';
import { atom } from 'recoil';

export const meUserState = atom<MeDetailsFragment | null>({
  key: 'userState',
  default: null,
});

export const meOrganizationState = atom<OrganizationResponseFragment | null>({
  key: 'organizationState',
  default: null,
});
