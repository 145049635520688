const currencyFormat = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

export const readablePrice = (cent: number): string => {
  const price = cent / 100;

  return `${currencyFormat.format(price)}`;
};
