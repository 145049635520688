import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import MenuToggler from './components/menu-toggler';
import { Support } from './components/support';
import { Basket } from './components/basket';
import { SupportMobile } from './components/support-mobile';
import { useAvailabilityContext } from '../../store/availability.context';
import { useNavigationContext } from '../../store/navigation.context';
import { readableUTCTime } from '../../helper/readable-time';
import { PageLoader } from './components/page-loader';
import { Chat } from './components/chat';
import { AvailabilityCheck } from '../AvailabilityCheck/AvailabilityCheck';
import dynamic from 'next/dynamic';
import { useGlobalSearchValues } from '../../store/recoil/global-search';
import { ProfileItem } from './components/profile-item/profile-item';
import { isFeatureFlagEnabled } from '../../lib/environment';
import styles from './page-header.module.scss';
import classNames from 'classnames';
import { PageHeaderLogo } from './components/page-header-logo';

const GlobalSearchInsideHeaderDynamic: React.ComponentType = dynamic(() =>
  import('../global-search/components/global-search-bar-inside-header-component').then((module) => module.GlobalSearchBarInHeader), { ssr: false });

// @todo: Load only if the user wants to open the navigation, otherwise prevent loading of the navigation.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Navigation = dynamic(() => import('./components/navigation/navigation').then((mod) => mod.Navigation), { ssr: false });
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const NavigationMobile = dynamic(() => import('./components/navigation/navigation-mobile').then((mod) => mod.NavigationMobile), { ssr: false });

let delayedComponentTimeout: NodeJS.Timeout;

const PageHeader: FC = () => {
  const { isOpen } = useNavigationContext();
  const [isInUse, setIsInUse] = useState<boolean>(false);
  const [t] = useTranslation(['common', 'search']);
  const { loadedDateTime, reload, isAvailabilityCheckVisible } = useAvailabilityContext();
  const { mainSearchVisible } = useGlobalSearchValues();
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [delayedComponent, setDelayedComponent] = useState(true);

  const firstRender = useMemo(() =>
    setIsFirstRender(true),
  [],
  );

  useEffect(() => {
    !mainSearchVisible && setIsFirstRender(false);
    firstRender;
  }, [mainSearchVisible]);

  useEffect(() => {
    clearTimeout(delayedComponentTimeout);

    delayedComponentTimeout = setTimeout(() => setDelayedComponent(mainSearchVisible), mainSearchVisible ? 300 : 0);
  }, [mainSearchVisible]);

  useEffect(() => {
    if (true === isOpen && false === isInUse) {
      setIsInUse(true);
    }
  }, [isOpen]);

  const availabilityCheck = (): ReactElement => {
    return (
      <div className={styles.availabilityCheckContainer}>
        <AvailabilityCheck
          lastUpdateLabel={t('search:last-update')}
          time={loadedDateTime ? readableUTCTime(loadedDateTime) : ''}
          onRequestUpdate={reload}
          oClockLabel={t('search:clock')}
          timeLabel={t('search:guaranteed-availability')}
          availabilityLabel={t('search:check-availability')}
          loadedDateTime={loadedDateTime}
        />
      </div>
    );
  };

  return <>
    <div className={classNames({
      [styles.pageHeaderContainer]: true,
    })}>
      <div className={styles.inner}>
        {isFeatureFlagEnabled('showHeaderPageLoader') && (
          <PageLoader />
        )}

        <div className={styles.topBar}>
          <SupportMobile />
          <Chat mobile={true} />
        </div>

        <section className={styles.pageHeaderInner}>
          <div className={styles.leftContainer}>
            <div className={styles.menuLeft}>
              <MenuToggler />
            </div>

            <div className={styles.logoWrapperMobile}>
              <Link href={'/'} passHref prefetch={false} aria-label='Digando.com'>
                <PageHeaderLogo isShortLogo={!delayedComponent} />
              </Link>
            </div>

            <div className={styles.logoWrapperDesktop}>
              <Link href={'/'} passHref prefetch={false} aria-label='Digando.com'>
                <>
                  <div className={classNames({
                    [styles.logoInnerLaptop]: true,
                  })}>
                    <PageHeaderLogo isShortLogo={!delayedComponent} />
                  </div>

                  <div className={classNames({
                    [styles.logoInnerDesktop]: true,
                  })}>
                    <PageHeaderLogo isShortLogo={false} />
                  </div>
                </>
              </Link>
            </div>
            {delayedComponent && <div className={styles.centerItem}>
              <Support />
              <Chat mobile={false} />
            </div>}
          </div>

          <div className={classNames({
            [styles.centerItemContainerDesktop]: true,
            [styles.isDelayedComponent]: delayedComponent,
          })}>
            {!isFirstRender && (
              <div className={classNames({
                [styles.globalSearchWrapper]: true,
                [styles.mainSearchIsVisible]: mainSearchVisible,
              })}>
                <GlobalSearchInsideHeaderDynamic />
              </div>
            )}
          </div>

          <div className={styles.rightContainer}>
            {null !== loadedDateTime && delayedComponent && isAvailabilityCheckVisible && (
              availabilityCheck()
            )}
            <ProfileItem small={!delayedComponent} />

            <Basket isHidden={false} />

            <div className={styles.menuRight}>
              <MenuToggler />
            </div>
          </div>

        </section>
      </div>
    </div>

    {true === isInUse && (
      <>
        <Navigation />
        <NavigationMobile />
      </>
    )}
  </>;
};

export default React.memo(PageHeader, () => true);
