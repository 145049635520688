import * as React from 'react';
function SvgLinkArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={49.776}
      height="6.18mm"
      viewBox="0 0 37.33 17.52"
      {...props}
    >
      <path d="M26.16 5.58H1c-.55 0-1 .43-1 .95v4.45c0 .53.45.95 1 .95h25.16v3.66c0 1.7 2.15 2.55 3.41 1.35l7.17-6.85c.78-.75.78-1.95 0-2.7L29.58.56c-1.26-1.2-3.41-.35-3.41 1.35v3.66h-.01z" />
    </svg>
  );
}
export default SvgLinkArrow;
