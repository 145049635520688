import { atom } from 'recoil';
import { BlogOverviewArticleFragment } from '../../../@types/codegen/graphql';

export const blogOverviewHeaderState = atom<BlogOverviewArticleFragment | null>({
  key: 'blogOverviewHeaderState',
  default: null,
});

export const blogOverviewNewPostsState = atom<BlogOverviewArticleFragment[]>({
  key: 'blogOverviewNewPostsState',
  default: [],
});

export const blogOverviewPostsState = atom<BlogOverviewArticleFragment[]>({
  key: 'blogOverviewPostsState',
  default: [],
});
