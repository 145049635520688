import * as React from 'react';
function SvgTwitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M10 4.833a2.5 2.5 0 00-2.5 2.5v12.778a8.057 8.057 0 008.055 8.055h6.167a2.5 2.5 0 100-5h-5.056A4.167 4.167 0 0112.5 19v-2.778c0-.46.373-.833.833-.833h8.389a2.5 2.5 0 100-5h-8.389a.833.833 0 01-.833-.833V7.334a2.5 2.5 0 00-2.5-2.5zm-2.946-.446a4.167 4.167 0 017.112 2.946v1.389h7.556a4.167 4.167 0 110 8.332h-7.556v1.944a2.5 2.5 0 002.5 2.5h5.056a4.167 4.167 0 110 8.332h-6.167a9.72 9.72 0 01-9.723-9.723V7.329c0-1.105.439-2.165 1.22-2.946z" />
    </svg>
  );
}
export default SvgTwitter;
