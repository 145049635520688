import * as React from 'react';
function SvgMapOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M5.759 26.833a.837.837 0 01-.833-.85.825.825 0 01.257-.584l20.833-20a.828.828 0 011.178.025c.154.16.237.371.232.594a.827.827 0 01-.255.584l-20.833 20a.836.836 0 01-.578.231zM15.987 25.167l-.122.001c-1.164 0-2.337-.181-3.486-.537a.835.835 0 01-.549-1.042.83.83 0 011.044-.549c.987.307 2 .462 3.01.462l.277.001c4.281 0 8.618-3.095 11.25-5.992a1.011 1.011 0 00-.002-1.356 21.98 21.98 0 00-3.288-2.948.83.83 0 01.491-1.506c.177 0 .347.056.491.16a23.716 23.716 0 013.541 3.175 2.677 2.677 0 01.003 3.592c-2.873 3.162-7.661 6.54-12.486 6.54l-.174-.002zM6.864 21.568a.83.83 0 01-.511-.176 23.992 23.992 0 01-2.992-2.762 2.676 2.676 0 01-.003-3.593c2.873-3.161 7.662-6.538 12.484-6.538l.263.001c.94 0 1.874.113 2.778.339a.832.832 0 11-.403 1.618 9.797 9.797 0 00-2.365-.289h-.255c-4.292 0-8.634 3.094-11.267 5.99-.347.384-.347.968.002 1.356a22.404 22.404 0 002.781 2.567.829.829 0 01-.512 1.487z" />
      <path d="M11.833 17.667a.834.834 0 01-.833-.833c0-1.333.52-2.588 1.464-3.533s2.199-1.467 3.532-1.467c.23 0 .416.186.417.416l.002.833c0 .111-.043.217-.121.296s-.183.122-.294.122c-.89 0-1.727.348-2.357.978s-.977 1.467-.977 2.356a.834.834 0 01-.833.833zM16.001 21.833a.417.417 0 01-.417-.417l-.001-.833a.415.415 0 01.416-.417c.891 0 1.728-.347 2.358-.977.629-.63.976-1.468.976-2.358a.833.833 0 011.666 0c0 1.336-.519 2.591-1.463 3.536s-2.2 1.466-3.536 1.466z" />
    </svg>
  );
}
export default SvgMapOff;
