import React, { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import {
  OrganizationService_Address,
  RegisterDocument,
  RegisterMutation,
  RegisterMutationVariables,
  RegisterService_Gender,
  RegisterService_RegisterOrganization,
  RegisterService_RegisterUser,
} from '../@types/codegen/graphql';
import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import Bugsnag from '@bugsnag/js';
import { IError } from '../lib/get-status-code';
import nookies from 'nookies';
import { DigandoCountryCode } from '../constants/cookies';
import { SignUpStep } from '../components/sidebar/interfaces/drawer.interfaces';
import { useWastebox } from '../hooks/use-wastebox';
import { signUpTracking } from '../lib/google/tracking-events';
import getConfig from 'next/config';

export interface ISignUpContextProps {
  inProgress: boolean;
  company: RegisterService_RegisterOrganization | null;
  registerErrors: string[];
  setRegisterErrors: React.Dispatch<React.SetStateAction<string[]>>;
  countryCode: OrganizationService_Address['countryCode'] | null;
  userData: RegisterService_RegisterUser;
  setCountryCode: (country: ISignUpContextProps['countryCode']) => void;
  setCompany: (companyAddress: RegisterService_RegisterOrganization | null) => void;
  setUserData: (userData: Omit<RegisterService_RegisterUser, 'organization'>) => void;
  signUpStep: SignUpStep;
  doRegister: () => Promise<boolean>;
  setSignUpStep: Dispatch<SetStateAction<SignUpStep>>;

}

const SignUpContext = createContext<ISignUpContextProps>({
  inProgress: false,
  company: null,
  userData: {
    gender: RegisterService_Gender.Male,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
  },
  countryCode: null,
  registerErrors: [],
  signUpStep: SignUpStep.SIGN_UP_COMPANY,
  setRegisterErrors: (): void => {
    // eslint-disable-next-line no-console
    console.error('SignUpContext not ready yet.');
  },
  setCompany: (): void => {
    // eslint-disable-next-line no-console
    console.error('SignUpContext not ready yet.');
  },
  setCountryCode: (): void => {
    // eslint-disable-next-line no-console
    console.error('SignUpContext not ready yet.');
  },
  setUserData: (): void => {
    // eslint-disable-next-line no-console
    console.error('SignUpContext not ready yet.');
  },
  doRegister: async (): Promise<boolean> => {
    // eslint-disable-next-line no-console
    console.error('SignUpContext not ready yet.');

    return false;
  },
  // eslint-disable-next-line no-console
  setSignUpStep: () => console.error('SignUpContext not ready yet.'),
});

const catchErrors = (errors: GraphQLError[]): string[] => {
  return errors.map(error => {
    return error.extensions.code as string ?? error.message ?? '';
  });
};

const { publicRuntimeConfig } = getConfig();

export const SignUpWrapper: FC<{ children: React.ReactNode; }> = ({ children }) => {
  const cookies = nookies.get();

  const cookieCountryCode = cookies[DigandoCountryCode] ?? null;

  const [signUpStep, setSignUpStep] = useState<SignUpStep>(SignUpStep.SIGN_UP_COMPANY);
  const [countryCode, setCountryCode] = useState<ISignUpContextProps['countryCode']>(cookieCountryCode);
  const [company, setCompany] = useState<RegisterService_RegisterOrganization | null>(null);
  const affiliateData = useWastebox();
  const [userData, setUserData] = useState<RegisterService_RegisterUser>({
    gender: RegisterService_Gender.Male,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
  });
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [registerErrors, setRegisterErrors] = useState<string[]>([]);
  const [register] = useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument);

  const doRegister = async (): Promise<boolean> => {
    setInProgress(true);

    if (!company) {
      setInProgress(false);
      setRegisterErrors(['company']);

      return false;
    }

    try {
      const res = await register({
        variables: {
          organization: company,
          user: userData,
          affiliateId: affiliateData?.affiliateId ?? '',
          crsAffiliateId: publicRuntimeConfig.crsAffiliateId ?? 'default',
        },
      });

      if (false === res?.data?.RegisterService_Register?.success) {
        throw new Error(res.data.RegisterService_Register.errorMessage ?? 'unknown-error');
      }

      setInProgress(false);
      setRegisterErrors([]);

      signUpTracking(userData?.email ?? 'unknown', affiliateData?.affiliateId ?? 'default');

      return res.data?.RegisterService_Register?.success ?? false;
    } catch (error: unknown) {
      console.error(error);
      Bugsnag.notify(JSON.stringify(error));

      const apolloError = JSON.parse(JSON.stringify(error));

      if (0 < (apolloError?.graphQLErrors?.length ?? 0)) {
        setRegisterErrors(catchErrors((error as IError)?.graphQLErrors ?? []));
        setInProgress(false);

        return false;
      }

      setRegisterErrors(['something-went-wrong']);
      setInProgress(false);

      return false;
    }
  };

  return (
    <>
      <SignUpContext.Provider value={{
        inProgress,
        company,
        countryCode,
        userData,
        registerErrors,
        signUpStep,
        setRegisterErrors,
        setCompany,
        setCountryCode,
        setUserData,
        doRegister,
        setSignUpStep,
      }}>
        {children}
      </SignUpContext.Provider>
    </>
  );
};

export function useSignUpContext(): ISignUpContextProps {
  return useContext(SignUpContext);
}
