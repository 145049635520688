export const size = {
  mobile: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1240px',
  desktop: '1600px'
};

// @deprecated Use mediaQueriesSizes instead. Reason: we need to add `all and` to the media query to enable the linting.
export const mediaQueries = {
  mobile: `all and (min-width: ${size.mobile})`,
  tablet: `all and (min-width: ${size.tablet})`,
  laptop: `all and (min-width: ${size.laptop})`,
  laptopL: `all and (min-width: ${size.laptopL})`,
  desktop: `all and (min-width: ${size.desktop})`
};

export const mediaQueriesSizes = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`
};
