import { FC } from "react";
import styles from "./button.module.scss";
import classNames from "classnames";
import { Loader } from "../loaders/loader";
import { IDigandoStylingButtonProps } from "./button";
import { UrlObject } from "url";
import Link from "next/link";

export interface IDigandoLinkButtonProps extends IDigandoStylingButtonProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
  href: UrlObject;
}

export const LinkButton: FC<IDigandoLinkButtonProps> = (props) => {
  const {
    onClick,
    onFocus,
    spacing = "default",
    small = false,
    bordered = false,
    loading = false,
  } = props;

  return (
    <Link
      className={classNames(styles.button, {
        [styles.bordered]: bordered,
        [styles.small]: small,
        [styles.spacingSmall]: spacing === "small",
        [styles.loading]: loading,
      })}
      onClick={onClick}
      onFocus={onFocus}
      href={props.href}
    >
      <span className={styles.buttonText}>{props.children}</span>
      <span className={styles.buttonLoader}>
        <Loader />
      </span>
    </Link>
  );
};
