import { Environments } from '../constants/environments';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

/**
 * Returns true if environments runs on production.
 */
export const isProduction = (): boolean => {
  return Environments.PRODUCTION === publicRuntimeConfig.appEnv;
};

/**
 * Returns true if nextjs runs on a Client Browser.
 */
export const isBrowser = (): boolean => {
  return 'undefined' !== typeof window;
};

export const isFeatureFlagEnabled = (featureName: string): boolean => {
  return publicRuntimeConfig.digandoFeatures[featureName];
}
