import React, { useEffect } from 'react';
import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import { useRouter } from 'next/router';

interface INavigationContextProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isUserDropdownMenuVisible: boolean;
  setIsUserDropdownMenuVisible: Dispatch<SetStateAction<boolean>>;
}

const NavigationContext = createContext<INavigationContextProps>({
  isOpen: false,
  // eslint-disable-next-line no-console
  setIsOpen: () => console.error('Auth Provider not initialized.'),
  isUserDropdownMenuVisible: false,
  // eslint-disable-next-line no-console
  setIsUserDropdownMenuVisible: () => console.error('Auth Provider not initialized.'),
});

export const NavigationWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isUserDropdownMenuVisible, setIsUserDropdownMenuVisible] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsOpen(false);
  }, [router.asPath]);

  return (
    <NavigationContext.Provider value={{
      isOpen,
      setIsOpen,
      isUserDropdownMenuVisible,
      setIsUserDropdownMenuVisible,
    }}>
      {children}
    </NavigationContext.Provider>
  );
};

export function useNavigationContext(): INavigationContextProps {
  return useContext(NavigationContext);
}
