import * as React from 'react';
function SvgInfo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M16 2.667a13.334 13.334 0 019.428 22.761A13.334 13.334 0 012.667 16 13.334 13.334 0 0116 2.667zM15.167 13.5h-.833a.833.833 0 000 1.666h.833v4.167a2.5 2.5 0 002.5 2.5h.833a.833.833 0 000-1.666h-.833a.833.833 0 01-.833-.833v-4.167a1.665 1.665 0 00-1.667-1.667zm0-4.667a1.332 1.332 0 00-1.308 1.593 1.328 1.328 0 001.048 1.048 1.326 1.326 0 001.368-.567 1.337 1.337 0 00-.166-1.684 1.334 1.334 0 00-.943-.391z" />
    </svg>
  );
}
export default SvgInfo;
