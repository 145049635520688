import { useState } from 'react';

import styles from './option.module.scss';
import classNames from 'classnames';

export interface IOptionItemProps<DataType> {
  option: AutocompleteItem<DataType>;
  setValue: (item: string) => void;
  onSelect: (option: AutocompleteItem<DataType>) => void;
}

/**
 * Represents the properties needed to handle an autocomplete option item.
 *
 * @template DataType - The type of data associated with the autocomplete option.
 */
export type AutocompleteItem<DataType> = {
  label: string;
  displayLabel?: string;
  data: DataType;
};

export function OptionItem<DataType>(props: IOptionItemProps<DataType>) {
  const { option, setValue, onSelect } = props;
  const [active, setActive] = useState<boolean>(false);

  return (
    <div
      className={classNames(styles.option, {
        [styles.active]: active,
      })}
      onClick={() => {
        onSelect(option);
        setValue(option.label);
      }}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {option.displayLabel ? option.displayLabel : option.label}
    </div>
  );
}
