import * as React from 'react';
function SvgInsta(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M2.656 3.5c0-.46.373-.833.833-.833h25c.46 0 .833.373.833.833v25c0 .46-.373.833-.833.833h-25a.833.833 0 01-.833-.833v-25zm1.666.833v23.333h23.333V4.333H4.322z" />
      <path d="M13.544 10.098a6.39 6.39 0 114.889 11.808 6.39 6.39 0 01-4.889-11.808zm2.445 1.18a4.728 4.728 0 00-3.339 1.383A4.71 4.71 0 0011.267 16a4.728 4.728 0 001.383 3.339A4.72 4.72 0 0020.711 16a4.728 4.728 0 00-1.383-3.339 4.71 4.71 0 00-3.339-1.383zM23.6 7.768a.833.833 0 00-1.389.621A1.386 1.386 0 0023.6 9.778a1.386 1.386 0 001.389-1.389.833.833 0 00-1.389-.621z" />
      <path d="M23.6 9.01a.833.833 0 001.389-.621A1.386 1.386 0 0023.6 7a1.386 1.386 0 00-1.389 1.389.833.833 0 001.389.621z" />
    </svg>
  );
}
export default SvgInsta;
