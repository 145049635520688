import * as React from 'react';
function SvgFaq(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M6.572 6.572a13.336 13.336 0 0118.856 0 13.336 13.336 0 010 18.856 13.336 13.336 0 01-18.856 0 13.336 13.336 0 010-18.856zM16 4.333a11.668 11.668 0 100 23.335 11.668 11.668 0 000-23.335zm.172 5.84a2.506 2.506 0 00-2.672 2.494.833.833 0 11-1.667-.001 4.163 4.163 0 014.453-4.155 4.173 4.173 0 013.537 2.501 4.167 4.167 0 01-2.435 5.584.83.83 0 00-.556.786V18.5a.833.833 0 01-1.666 0v-1.117a2.504 2.504 0 011.666-2.358 2.505 2.505 0 001.643-2.013 2.501 2.501 0 00-2.304-2.838zm-.866 11.038a1.25 1.25 0 011.944 1.039 1.243 1.243 0 01-.772 1.154 1.248 1.248 0 01-1.704-.911 1.255 1.255 0 01.531-1.283zm.853 1.424a.42.42 0 00.25-.466.415.415 0 00-.793-.077.414.414 0 00.543.543z" />
    </svg>
  );
}
export default SvgFaq;
