import * as React from 'react';
function SvgDelete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M4.018 9.916a.836.836 0 00.987.641l7.279-1.547a.599.599 0 00.058-.009l8.15-1.732a.568.568 0 00.047-.013l7.289-1.55a.83.83 0 00.643-.988.836.836 0 00-.987-.641l-6.521 1.386-.172-.811a2.489 2.489 0 00-1.081-1.579 2.487 2.487 0 00-1.88-.352L12.94 3.76c-.653.138-1.214.522-1.579 1.082s-.49 1.23-.352 1.887l.173.812-6.521 1.386a.834.834 0 00-.641.989zm8.737-4.165a.83.83 0 01.528-.361l4.894-1.04a.824.824 0 01.621.119.827.827 0 01.361.527l.172.812-6.521 1.387-.173-.817a.826.826 0 01.118-.627zm1.244 16.916a.833.833 0 001.666 0v-8.333a.833.833 0 00-1.666 0v8.333zm5.834.833a.833.833 0 01-.833-.833v-8.333a.833.833 0 011.666 0v8.333a.834.834 0 01-.833.833zM8.876 27.04a2.513 2.513 0 002.49 2.293h11.933a2.514 2.514 0 002.491-2.289l1.539-17.638a.836.836 0 00-.83-.907h-2.917a.833.833 0 000 1.666h2.008l-1.461 16.734a.84.84 0 01-.836.765H11.365a.838.838 0 01-.83-.765L9.383 13.013a.836.836 0 00-.899-.761.834.834 0 00-.761.9l1.152 13.886z" />
    </svg>
  );
}
export default SvgDelete;
