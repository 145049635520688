import { ButtonHTMLAttributes, FC } from 'react';
import styles from './button.module.scss';
import classNames from 'classnames';
import { Loader } from '../loaders/loader';
import Link from 'next/link';
import { UrlObject } from 'url';

export interface IDigandoStylingButtonProps {
  spacing?: 'small' | 'default';
  disabled?: boolean;
  loading?: boolean;
  small?: boolean;
  bordered?: boolean;
  fullWidth?: boolean;
  ghost?: boolean;
  href?: UrlObject;
  uppercase?: boolean;
}

export interface IDigandoButtonProps extends IDigandoStylingButtonProps {
  type?: ButtonHTMLAttributes<void>['type'];
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
}

export const Button: FC<IDigandoButtonProps> = (props) => {
  const {
    onClick,
    onFocus,
    type = 'submit',
    spacing = 'default',
    small = false,
    bordered = false,
    disabled = false,
    loading = false,
    fullWidth = false,
    ghost = false,
    href,
    uppercase = true,
  } = props;

  if (href) {
    return (
      <Link
        href={href}
        className={classNames(styles.button, {
          [styles.bordered]: bordered,
          [styles.small]: small,
          [styles.fullWidth]: fullWidth,
          [styles.spacingSmall]: spacing === 'small',
          [styles.loading]: loading,
          [styles.ghost]: ghost,
        })}
      >
        <span className={classNames({
          [styles.uppercase]: uppercase
        })}>{props.children}</span>
        <span className={styles.buttonLoader}>{loading && <Loader invert={false === bordered} />}</span>
      </Link>
    );
  }

  return (
    <button
      className={classNames(styles.button, {
        [styles.bordered]: bordered,
        [styles.small]: small,
        [styles.fullWidth]: fullWidth,
        [styles.spacingSmall]: spacing === 'small',
        [styles.loading]: loading,
        [styles.ghost]: ghost,
      })}
      type={type}
      onClick={onClick}
      onFocus={onFocus}
      disabled={disabled}
    >
      <span className={classNames({
        [styles.uppercase]: uppercase
      })}>{props.children}</span>
      <span className={styles.buttonLoader}>{loading && <Loader invert={false === bordered} />}</span>
    </button>
  );
};
