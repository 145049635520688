import React, { FC } from 'react';
import { appWithTranslation } from 'next-i18next';
import { AppInitialProps } from 'next/app';
import { NextComponentType } from 'next';

import PageHeader from '../components/page-header/page-header';
import { PageFooter } from '../components/page-footer/page-footer';
import { AuthWrapper } from '../store/auth.context';
import { CartWrapper } from '../store/cart.context';
import { AvailabilityWrapper } from '../store/availability.context';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import nookies from 'nookies';
import getConfig from 'next/config';
import { isBrowser, isFeatureFlagEnabled } from '../lib/environment';
import { Theme, ThemeProvider } from '@emotion/react';
import useAffiliateLinkVisitTracking from '../hooks/tracking/affiliates/useAffiliateLinkVisitTracking';
import { Sidebar } from '../components/sidebar/sidebar';
import { NavigationWrapper } from '../store/navigation.context';
import { CategoriesWrapper } from '../store/categories.context';
import { GlobalSearchWrapper } from '../store/global-search.context';
import { RecoilRoot } from 'recoil';
import { initializeRecoilState } from '../store/recoil/initialization-states';
import Script from 'next/script';

import { ConfigProvider } from 'antd';
import { LoaderLayout } from '../components/loader/loader-layout';
import { theme } from '@digando/react-component-library';
import { StyleProvider } from '@ant-design/cssinjs';
import {
  SearchLocationOverlayLoader,
} from '../components/digando-overlay/search-location-overlay/search-location-overlay-loader';
import { SidebarBase } from '../components/sidebar/sidebar-base';
import { SidebarWrapper } from '../store/sidebar.context';
import { SearchTermOverlayLoader } from '../components/digando-overlay/search-term-overlay/search-term-overlay-loader';
import styles from './../styles/app.module.scss';
import classNames from 'classnames';

import '../styles/global.scss';
import '@digando/themes/theme.scss';
import SessionProvider from '../store/session.provider';
import { AccessoryConfiguratorWrapper } from '../components/configurator/accessory.contexts';

const { publicRuntimeConfig } = getConfig();

Bugsnag.start({
  apiKey: publicRuntimeConfig.bugsnagApiKey,
  appVersion: 'monorepo', // @todo: Replace version
  appType: isBrowser() ? 'client' : 'ssr',
  releaseStage: publicRuntimeConfig.appEnv,

  plugins: [new BugsnagPluginReact()],
  onError: function(event) {
    const cookies = nookies.get();

    // @todo: Update implementation (to to use Next.js middleware?)
    event.addMetadata('cookies', cookies);
    // @todo: Add back when we have a better way to get the location
    // event.addMetadata('location', { pathname: location?.pathname ?? '' });
    return publicRuntimeConfig.bugsnagEnabled;
  },
});

export const ErrorBoundary = (Bugsnag.getPlugin('react')?.createErrorBoundary(React) as FC<{
  children: React.ReactNode;
}>) ?? <></>;

export interface IAppProps extends AppInitialProps {
  Component: NextComponentType;
}

const defaultTheme: Theme = {
  contentType: 'default',
};

const App: React.FunctionComponent<IAppProps> = ({ Component, pageProps }) => {
  // Track affiliates in _app enables us to be flexible in routes which are used for /api/affiliate redirects.
  useAffiliateLinkVisitTracking();

  return (
    <ErrorBoundary>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: 'var(--primary-color)',
            colorText: `var(--black)`,
            colorTextDisabled: `var(--black)`,
            colorPrimaryActive: theme.primaryHoverColor,
            colorLink: 'var(--primary-color)',
            colorLinkHover: 'var(--primary-color)',
            colorLinkActive: 'var(--primary-color)',
            fontFamily: 'var(--font-primary-regular-family)',
            fontWeightStrong: 600,
            fontSize: 17,
          },
        }}
      >
        <StyleProvider hashPriority='high'>
          <SessionProvider
            session={pageProps.session}
            apolloState={pageProps.apolloState}
          >
            <RecoilRoot initializeState={initializeRecoilState(pageProps.data)}>
              <CategoriesWrapper>
                <AuthWrapper>
                  <CartWrapper>
                    <AvailabilityWrapper
                      ssrTotalProducts={pageProps.ssrTotalProducts}
                      ssrLoadedDateTime={pageProps.ssrLoadedDateTime}
                    >
                      <GlobalSearchWrapper>
                        <AccessoryConfiguratorWrapper>

                          <ThemeProvider theme={defaultTheme}>
                            <SidebarWrapper>
                              <div id={'page-content-wrapper'} className={classNames({
                                [styles.appContentWrapper]: true,
                              })}>
                                {isFeatureFlagEnabled('is3rdPartyTrackingEnabled') && (
                                  <>
                                    <Script
                                      id='usercentrics-cmp'
                                      data-settings-id='kfL5yuYI'
                                      src='https://app.usercentrics.eu/browser-ui/latest/loader.js'
                                      async
                                    />

                                    <Script id='google-analytics' strategy='afterInteractive'>
                                      {`
                                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                  })(window,document,'script','dataLayer','${publicRuntimeConfig.gtmKey}');
                                `}
                                    </Script>
                                  </>
                                )}

                                <NavigationWrapper>
                                  <PageHeader />
                                </NavigationWrapper>
                                <main>
                                  <LoaderLayout>
                                    <Component {...pageProps} />
                                  </LoaderLayout>
                                </main>

                                <PageFooter />
                                <Sidebar />
                                <SidebarBase />
                                <SearchTermOverlayLoader />
                                <SearchLocationOverlayLoader />
                              </div>
                            </SidebarWrapper>
                          </ThemeProvider>
                        </AccessoryConfiguratorWrapper>
                      </GlobalSearchWrapper>
                    </AvailabilityWrapper>
                  </CartWrapper>
                </AuthWrapper>
              </CategoriesWrapper>
            </RecoilRoot>
          </SessionProvider>
        </StyleProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default appWithTranslation(App);
