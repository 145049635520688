import * as React from 'react';
function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M27.389 2.667H4.611a1.944 1.944 0 00-1.945 1.945V27.39a1.947 1.947 0 001.944 1.945h11.556c.46 0 .833-.373.833-.833v-9.444l-.009-.123a.833.833 0 00-.824-.71l-2.5-.001v-2.777h2.5c.46 0 .833-.373.833-.833v-1.178c0-1.456.578-2.852 1.608-3.881l.226-.214a5.49 5.49 0 013.655-1.394h1.732v2.777l-1.732.001a2.718 2.718 0 00-1.917.794 2.718 2.718 0 00-.794 1.917v1.178l.009.123c.059.402.406.71.824.71h2.956l-.352 2.777-2.604.001a.833.833 0 00-.833.833v9.444c0 .46.373.833.833.833h6.778a1.944 1.944 0 001.945-1.945V4.612a1.944 1.944 0 00-1.945-1.944zM4.611 4.333h22.778a.276.276 0 01.277.277v22.778a.276.276 0 01-.081.196l-.058.044a.28.28 0 01-.139.037h-5.945v-7.779l2.012.001a1.388 1.388 0 001.382-1.223l.422-3.328a1.397 1.397 0 00-.342-1.096 1.402 1.402 0 00-.473-.345l-.138-.052a1.392 1.392 0 00-.435-.067h-2.428v-.344a1.039 1.039 0 01.306-.738 1.052 1.052 0 01.739-.306h2.011a1.386 1.386 0 001.389-1.389V7.666a1.386 1.386 0 00-1.389-1.389h-2.011a7.156 7.156 0 00-5.06 2.096l-.245.257a7.158 7.158 0 00-1.851 4.803v.344h-1.944a1.386 1.386 0 00-1.389 1.389v3.333c0 .368.146.722.407.982l.138.121c.241.185.538.286.845.286l1.944-.001v7.779H4.611a.276.276 0 01-.277-.277V4.611a.276.276 0 01.277-.277z" />
    </svg>
  );
}
export default SvgFacebook;
