import { FC, createContext, useContext } from "react";
import { selectedAccessoriesManuallyState, selectedAccessoriesState } from '../../store/recoil/product-configurator';
import { useRecoilState } from "recoil";
import { ProductBundleAccessoriesFragment } from '../../@types/codegen/graphql';

type AccessoryConfiguratorContextProps = {
  resetSelectedAccessories: (accessories: ProductBundleAccessoriesFragment[]) => void;
  setPreselectedAccessories: (accessories: ProductBundleAccessoriesFragment[]) => void;
  selectAccessory: (accessory: ProductBundleAccessoriesFragment) => void;
};

type AccessoryConfiguratorWrapperProps = {
  children: React.ReactNode;
};

const AccessoryConfiguratorContext = createContext<AccessoryConfiguratorContextProps>({
  resetSelectedAccessories: () => console.error('no AccessoryConfiguratorContextProvider'),
  setPreselectedAccessories: () => console.error('no AccessoryConfiguratorContextProvider'),
  selectAccessory: () => console.error('no AccessoryConfiguratorContextProvider'),
});

export const AccessoryConfiguratorWrapper: FC<AccessoryConfiguratorWrapperProps> = ({children}) => {
  const [selectedAccessories, setSelectedAccessories] = useRecoilState(selectedAccessoriesState);
  const [selectedAccessoriesManually, setSelectedAccessoriesManually] = useRecoilState(selectedAccessoriesManuallyState);

  const resetSelectedAccessories = (accessories:  ProductBundleAccessoriesFragment[]) => {
    const accessoriesIds = accessories.map(accessory => accessory.accessoryType?.identifier ?? '');

    const selected = selectedAccessories.filter((identifier) => {
      return accessoriesIds.includes(identifier);
    });

    setSelectedAccessories(selected);
    setSelectedAccessoriesManually(false);
  }

  const setPreselectedAccessories = (accessories: ProductBundleAccessoriesFragment[]) => {
    if (true === selectedAccessoriesManually) {
      return;
    }

    const accessoriesIds = accessories
      .filter(accessory => accessory.isPreselected)
      .map(accessory => accessory.accessoryType?.identifier ?? '');

    setSelectedAccessories(accessoriesIds);
  }

  const selectAccessory = (accessory: ProductBundleAccessoriesFragment) => {
    const selected = selectedAccessories.filter(identifier => {
      return identifier !== accessory.accessoryType?.identifier;
    });

    if (-1 === selectedAccessories.indexOf(accessory.accessoryType?.identifier ?? '')) {
      selected.push(accessory.accessoryType?.identifier ?? '');
    }

    setSelectedAccessories(selected);
    setSelectedAccessoriesManually(true);
  };

  return (
    <AccessoryConfiguratorContext.Provider value={{
      resetSelectedAccessories,
      setPreselectedAccessories,
      selectAccessory,
    }}>
      {children}
    </AccessoryConfiguratorContext.Provider>
  );
}

export function useConfiguratorAccessoryContext() {
  return useContext(AccessoryConfiguratorContext);
}
