import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { spacingTopBottom } from '@digando/react-component-library';
import { mediaQueries } from '../../../styles/media-queries';

export const PageLoader: FC = () => {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (): void => {
      setLoading(true);
    };

    const handleComplete = (): void => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return (): void => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  return (
    <LoadingContainer>
      {loading && (<div className={'loading'} />)}
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  position: relative;
  z-index: 200;
  width: 100%;
  background-color: var(--primary-color);
  height: auto;
  ${spacingTopBottom(0.5)};
  overflow: hidden;

  @media ${mediaQueries.laptop} {
    padding: 0;
    height: var(--spacing-steps);
  }

  @media print {
    display: none;
  }

  .loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background: linear-gradient(90deg, var(--secondary-color) 90%, var(--primary-color) 100%);

    animation: AnimationName 5s linear infinite;
  }

  @keyframes AnimationName {
    0%{
      opacity: 1;
      width: 0%;
    }
    90%{
      opacity: 1;
      width: 110%;
    }
    100%{
      opacity: 0;
      width: 100%;
    }
  }
`;
