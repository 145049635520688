import React, { FC } from 'react';

import { FontPrimaryRegular, FontPrimarySemiBold, Icon, spacingLeft } from '@digando/react-component-library';
import styled from '@emotion/styled';

import { WidgetDescription } from '../styles/widget-description';
import { WidgetContainer } from '../styles/widget-container';
import { mediaQueries } from '../../../styles/media-queries';
import { useTranslation } from 'next-i18next';
import getConfig from 'next/config';
import { isFeatureFlagEnabled } from '../../../lib/environment';

export interface IMobileSize {
  mobile?: boolean;
}

/* eslint-disable */
export interface HubspotWindow extends Window {
  hsConversationsOnReady: any;
  HubSpotConversations: any;
}

declare const window: HubspotWindow;
/* eslint-enable */

export const Chat: FC<IMobileSize> = (props) => {
  const [t] = useTranslation('common');

  if (!isFeatureFlagEnabled('isChatEnabled')) {
    return null;
  }

  const { mobile } = props;

  const { publicRuntimeConfig } = getConfig();

  function loadChat(): void {
    const findScript = document.getElementById('hubspotScript');

    if (!findScript) {
      const script = document.createElement('script');

      script.src = `https://js.hs-scripts.com/${publicRuntimeConfig.hubspotPortalId}.js`;
      script.async = true;
      script.id = 'hubspotScript';

      document.body.appendChild(script);

      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.open();
      } else {
        window.hsConversationsOnReady = [(): void => {
          const interval = setInterval(() => {
            if(window.HubSpotConversations.widget.status().loaded) {
              setTimeout(() => {
                window.HubSpotConversations.widget.open();
              }, 300);
              clearInterval(interval);
            }
          }, 100);
        }];
      }

    } else if(window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    }
  }

  return (
    <>
      {false === mobile && (
        <ChatWidgetContainer onClick={(): void => loadChat()}>
          <ChatIcon icon={'chat-new'} size={'30px'} color={'var(--page-header-font-color)'}/>
          <ChatText isHidden={false} fontWeightBold={false}>
            {t('live-chat')}
          </ChatText>
        </ChatWidgetContainer>
      )}
      {true === mobile && (
        <MobileChatWidgetContainer onClick={(): void => loadChat()}>
          <ChatIcon icon={'chat-new'} size={'24px'}  color={'var(--page-header-font-color)'} />
          <MobileChatText isHidden={false}>
            {t('live-chat')}
          </MobileChatText>
        </MobileChatWidgetContainer>
      )}
    </>
  );
};

const ChatWidgetContainer = styled(WidgetContainer)`
  display: none;

  @media ${mediaQueries.tablet} {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

const MobileChatWidgetContainer = styled(WidgetContainer)`
  display: flex;
  background-color: var(--black);
  cursor: pointer;

  @media ${mediaQueries.tablet} {
    background-color: var(--primary-color);
    margin-top: 0px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  @media ${mediaQueries.tablet} {
    display: none;
  }
`;

const ChatIcon = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
`;

const ChatText = styled(WidgetDescription)<{ fontWeightBold: boolean }>`
  max-width: none;
  ${(p) => (p.fontWeightBold ? FontPrimarySemiBold : FontPrimaryRegular)};
  color: var(--page-header-font-color)
`;

const MobileChatText = styled(WidgetDescription)`
  @media (max-width: 330px) {
    font-size: 12px;
  }

  display: inline-block;
  vertical-align: middle;
  max-width: none;
  font-size: var(--font-size-text-small);
  line-height: 22px;
  color: var(--page-header-font-color);
  ${spacingLeft(1)};
`;
