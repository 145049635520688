import * as React from 'react';
function SvgSortDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M16 2.661c.46 0 .833.373.833.833v20a.833.833 0 01-1.666 0v-20c0-.46.373-.833.833-.833z" />
      <path d="M11.244 18.739a.834.834 0 011.178 0l3.577 3.577 3.577-3.577a.834.834 0 011.178 1.178l-4.167 4.167a.834.834 0 01-1.178 0l-4.167-4.167a.834.834 0 010-1.178zM2.667 28.495c0-.46.373-.833.833-.833h25a.833.833 0 010 1.666h-25a.833.833 0 01-.833-.833z" />
    </svg>
  );
}
export default SvgSortDown;
