import { FC } from 'react';
import styles from './tag.module.scss';
import { ITagProps, Tag } from './tag';
import { TagText } from './tag-text';

export interface ITagItemProps {
  tagText: string;
  tags: ITagProps[];
}

export const TagItem: FC<ITagItemProps> = (props) => {
  const { tagText, tags } = props;

  return (
    <div className={styles.tagItem}>
      <TagText tagText={tagText} />
      <div className={styles.tagItems}>
        {tags.map((tag) => (
          <Tag key={tag.tagKey} text={tag.text} tagKey={tag.tagKey} onClick={tag.onClick} />
        ))}
      </div>
    </div>
  );
};
