import styles from './../inputs.module.scss';
import { Icon } from '../../icons/icon';
import { Loader } from '../../loaders/loader';
import { Input } from '../input';
import { InputError } from '../form.hook';

interface IAutocompleteInputFieldProps {
  id: string;
  name: string;
  label: string;
  inputValue: string;
  setInputValue: (value: string) => void;
  autoComplete?: string;
  autoFocus: boolean;
  isLocation: boolean;
  getLocationOfUser?: () => void;
  getOptions: (value: string) => Promise<void>;
  onClick?: () => void;
  onDelete?: () => void;
  setFocused: (focused: boolean) => void;
  isLoadingLocation: boolean;
  isLoading?: boolean;
  onBlur?: () => void;
  onChange?: (newValue: string) => void;
  error: InputError | null;
}

export function AutoCompleteInputField(props: IAutocompleteInputFieldProps) {
  const {
    name,
    inputValue,
    setInputValue,
    id,
    autoFocus,
    autoComplete,
    onClick,
    onDelete,
    getOptions,
    setFocused,
    label,
    isLocation,
    getLocationOfUser,
    isLoadingLocation,
    isLoading,
    onBlur,
    error,
  } = props;

  return (
    <div>
      <Input
        id={id}
        name={name}
        value={inputValue}
        label={label}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        onChange={(event, value) => {
          setInputValue(value);
        }}
        error={error}
        onClick={onClick}
        onBlur={() => {
          setTimeout(() => {
            setFocused(false);
          }, 200);

          if (onBlur) {
            onBlur();
          }
        }}
        onFocus={async () => {
          setFocused(true);
          await getOptions(inputValue);
        }}
        onDelete={onDelete}
      />

      {isLoading ||
        (isLoadingLocation && (
          <span className={styles.loaderIcon}>
            <span className={styles.loader}>
              <Loader />
            </span>
          </span>
        ))}

      {inputValue === '' && isLocation && !isLoadingLocation && (
        <span
          className={styles.locationIcon}
          onClick={() => {
            if (getLocationOfUser) {
              getLocationOfUser();
            }
          }}
        >
          {<Icon icon={'location'} color={'var(--primary-color)'} />}
        </span>
      )}
    </div>
  );
}
