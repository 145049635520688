import { CategoryFragment, CategoryTreeFragment } from '../@types/codegen/graphql';

export const flatCategories = (rootCategory: CategoryTreeFragment): CategoryTreeFragment[] => {
  if (!rootCategory) {
    return [];
  }

  const firstLevelCategories = rootCategory.children as CategoryTreeFragment[];

  const allCategories: CategoryFragment[] = [];

  if (rootCategory) {
    allCategories.push(rootCategory);
  }

  firstLevelCategories.forEach(category => {
    if (null !== category) {
      allCategories.push(category);

      const subCategories = category.children;

      subCategories?.forEach(children => {
        if (null !== children) {
          allCategories.push(children);
        }
      });
    }
  });

  return allCategories;
};
