import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { Loader } from '@digando/common-ui';
import { Backdrop } from '../elements/Backdrop/Backdrop';
import { useSidebarContext } from '../../store/sidebar.context';
import { useAuthProcessContentHook } from './layers/sign-in/hooks/auth-process-content';
import styles from './sidebar-base.module.scss';
import classNames from 'classnames';

const importMapping: {
  [key: string]: { Header: React.ComponentType; Content: React.ComponentType };
} = {
  addressForm: {
    Header: dynamic(() => import('./layers/address-form/address-form').then((mod) => mod.Header), {
      ssr: false,
    }),
    Content: dynamic(
      () => import('./layers/address-form/address-form').then((mod) => mod.AddressForm),
      { ssr: false, loading: () => <Loader /> }
    ),
  },
};

const SidebarComponent: FC = () => {
  useAuthProcessContentHook();
  // @todo manage sidebar state (currently only addressForm is available)
  // eslint-disable-next-line
  const { isOpen, setIsOpen } = useSidebarContext();

  // const Component = importMapping?.[sidebarState] ?? null;
  const Component = isOpen ? importMapping?.['addressForm'] ?? null : null;

  return (
    <>
      <Backdrop isOpen={isOpen} onClick={(): void => setIsOpen(false)} />
      <div className={classNames({
        [styles.sidebarContainer]: true,
        [styles.isOpen]: isOpen,
      })}>
        {Component?.Header && <Component.Header />}
        <div className={styles.sidebarContent} data-e2e-id={'DigandoSidebarContent'}>
          {Component?.Content && <Component.Content />}
        </div>
      </div>
    </>
  );
};

export const SidebarBase = React.memo(SidebarComponent, () => true);
