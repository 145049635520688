import { FC } from 'react';
import styles from './tag.module.scss';

export interface ITagTextProps {
  tagText: string;
}

export const TagText: FC<ITagTextProps> = (props) => {
  const { tagText } = props;

  return <div className={styles.tagText}>{tagText}</div>;
};
