import { css } from '@emotion/react';

export const FontPrimaryRegular = css`
  font-family: var(--font-primary-regular-family);
  font-weight: var(--font-primary-regular-weight);
  font-style: var(--font-primary-regular-style);
  font-display: optional;
`;

export const FontPrimarySemiBold = css`
  font-family: var(--font-primary-semi-bold-family);
  font-weight: var(--font-primary-semi-bold-weight);
  font-style: var(--font-primary-semi-bold-style);
  font-display: optional;
`;

export const FontSecondaryLight = css`
  font-family: var(--font-secondary-light-family);
  font-weight: var(--font-secondary-light-weight);
  font-style: var(--font-secondary-light-style);
  font-display: optional;
`;

export const FontSecondaryRegular = css`
  font-family: var(--font-secondary-regular-family);
  font-weight: var(--font-secondary-regular-weight);
  font-style: var(--font-secondary-regular-style);
  font-display: optional;
`;

export const FontSecondaryRegularItalic = css`
  font-family: var(--font-secondary-regular-italic-family);
  font-weight: var(--font-secondary-regular-italic-weight);
  font-style: var(--font-secondary-regular-italic-style);
  font-display: optional;
`;

export const FontSecondarySemiBold = css`
  font-family: var(--font-secondary-semi-bold-family);
  font-weight: var(--font-secondary-semi-bold-weight);
  font-style: var(--font-secondary-semi-bold-style);
  font-display: optional;
`;
