import { css } from '@emotion/react';

export const
  GridLayout = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 16px;

  margin-left: auto;
  margin-right: auto;
`;

export const GridLayoutTen = css`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 16px;

  margin-left: auto;
  margin-right: auto;
`;
