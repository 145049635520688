import * as React from 'react';
function SvgShield(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M15.833 2.668a25.123 25.123 0 0110.269 2.096l.016.007a2.655 2.655 0 011.549 2.425V7.2l-1-.007h1v8.46a14.228 14.228 0 01-9.121 13.28l-1.157.444a4.334 4.334 0 01-3.111 0l-1.157-.444A14.231 14.231 0 014 15.653V7.2l1-.007h1v8.46a12.23 12.23 0 007.838 11.414l1.157.444a2.337 2.337 0 001.675 0l1.156-.444a12.231 12.231 0 007.838-11.414V7.187a.655.655 0 00-.374-.595l.01.004.4-.916-.416.909.006.003a23.09 23.09 0 00-9.449-1.924h-.019c-3.25-.03-6.47.626-9.449 1.924l.006-.003-.416-.909.4.917.01-.004a.652.652 0 00-.374.595l-1 .007h-1v.003a2.648 2.648 0 011.549-2.425l.016-.007A25.117 25.117 0 0115.833 2.67zm1 5.999c0 .552-.447 1-.999 1.001a4.84 4.84 0 00-4.394 2.827 4.838 4.838 0 00.744 5.171 4.837 4.837 0 005.013 1.472 4.833 4.833 0 003.423-3.947 1 1 0 111.98.286 6.837 6.837 0 01-4.84 5.581 6.83 6.83 0 01-7.087-2.082 6.835 6.835 0 01-1.053-7.311 6.836 6.836 0 016.212-3.997c.552 0 1 .447 1.001.999zm5.5 2.652a.999.999 0 10-1.414-1.414l-5.126 5.126-1.474-1.479a1 1 0 10-1.416 1.412l1.669 1.674a1.732 1.732 0 002.444 0l5.318-5.318z" />
    </svg>
  );
}
export default SvgShield;
