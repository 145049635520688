import { useSession } from 'next-auth/react';
import { FC } from 'react';
import { LoggedInProfileItem } from './logged-in';
import { LoggedOutProfileItem } from './logged-out';
import { WidgetContainer } from '../../styles/widget-container';

export type ProfileItemProps = {
  small: boolean;
}

export const ProfileItem: FC<ProfileItemProps> = (props) => {
  const { status } = useSession();

  if (status === 'authenticated') {
    return (
      <WidgetContainer style={{position: 'relative'}}>
        <LoggedInProfileItem {...props} />
      </WidgetContainer>
    );
  } else {
    return (
      <WidgetContainer>
        <LoggedOutProfileItem {...props} />
      </WidgetContainer>
    );
  }
};
